import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "~/lib/lifecycle-helpers";
import { useToasts } from "~/lib/toast/use-toasts";

type Location = {
  latitude: number;
  longitude: number;
};

/**
 * Returns the location. If not available, it will return a null value
 *
 * @returns Location | undefined | null - The location if available, undefined not ready, null if the location is not available
 */
export function useLocationIfAvailable(): Location | undefined | null {
  const toast = useToasts();
  const [location, setLocation] = useState<Location | undefined | null>(undefined);
  const { t } = useTranslation();

  async function detectLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const currentPosition = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        setLocation(currentPosition);
      },
      () => {
        toast.showTemplate("OPERATION_FAILED", {
          title: t("forms:location_error"),
          description: t("forms:location_error_description"),
        });
        setLocation(null);
      }
    );
  }
  useMount(() => {
    detectLocation();
  });

  return location;
}
