import { ComponentProps, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { twMerge } from "tailwind-merge";
import { Button } from "./buttons/button";
import { ButtonSizes } from "./buttons/shared/button.shared";
import { getIcon } from "./icons/icon";
import { ActionTooltipWrapper } from "./action-tooltip-wrapper";

export interface ButtonAction extends ComponentProps<typeof Button> {
  isHidden?: boolean;
  collapseBehavior?: "default" | "always" | "never";
  label: string;
}

/**
 * Creates a button group with a dropdown for additional actions
 * - Do not use this as a dropdown menu
 */
export function ActionButtons({
  actions,
  collapseAt = 3,
  buttonClassName,
  dropdownClassName,
  size = "medium",
}: {
  actions: Array<ButtonAction>;
  collapseAt?: number;
  buttonClassName?: string;
  dropdownClassName?: string;
  size?: ButtonSizes;
  /** Affects when not collapsed */
}) {
  const [open, setOpen] = useState<boolean>(false);

  const shownActions = actions.filter((action) => !action.isHidden);

  // Show all buttons with collapse=never, and the remaining buttons up to collapseAt
  const buttonActions: Array<ButtonAction> = [];
  const dropdownActions: Array<ButtonAction> = [];

  for (const a of shownActions) {
    if (a.collapseBehavior === "never") {
      buttonActions.push(a);
      continue;
    }
    if (a.collapseBehavior === "always") {
      dropdownActions.push(a);
      continue;
    }
    if (buttonActions.length < collapseAt) {
      buttonActions.push(a);
      continue;
    }
    dropdownActions.push(a);
  }

  const baseClass = twMerge(
    "relative inline-flex items-center border border-gray-300 bg-white bg-white px-4 py-2 text-sm font-medium text-gray-700 text-gray-700 hover:border-hover hover:text-hover focus:border-indigo-500 focus-visible:outline-hover",
    "hover:border-text-gray-400 disabled:text-gray-400"
  );

  const showButtons = buttonActions.length > 0;
  const showDropdown = dropdownActions.length > 0;
  const iconSizeClasses = size === "small" ? "h-5 w-5" : "h-6 w-6";
  return (
    <>
      <span
        className={twMerge(
          "isolate hidden rounded-md shadow-sm",
          (showButtons || showDropdown) && "lg:inline-flex"
        )}
      >
        {buttonActions.map((actionProps, idx) => {
          const isFirst = idx === 0;
          const isLast = (() => {
            if (showDropdown) return false;
            return idx === buttonActions.length - 1;
          })();

          const { isHidden: _isHidden, label, ...buttonProps } = actionProps;

          return (
            <ActionTooltipWrapper key={idx} tooltip={label}>
              <Button
                {...buttonProps}
                size={size}
                className={twMerge(
                  baseClass,
                  isFirst && "rounded-l-md",
                  isLast && "rounded-r-md",
                  isLast && "last",
                  !isLast && "border-r-0",
                  buttonClassName
                )}
                iconClassName={iconSizeClasses}
              />
            </ActionTooltipWrapper>
          );
        })}

        <Popover.Root open={open} onOpenChange={setOpen}>
          <Popover.Trigger className={twMerge(!showDropdown && "hidden")} asChild>
            <Button
              Icon={getIcon("menuKebab")}
              size={size}
              className={twMerge(
                baseClass,
                !showDropdown && "hidden",
                !showButtons && "rounded-l-md p-2",
                "rounded-r-md",
                dropdownClassName
              )}
              iconClassName={iconSizeClasses}
            />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content align="end" sideOffset={10}>
              <div className="min-w-[10rem] rounded-lg bg-white p-2 shadow">
                {dropdownActions.map((a, idx) => (
                  <Button
                    key={`item-${idx}`}
                    {...a}
                    className="flex w-full cursor-pointer gap-2 rounded-lg py-2 hover:bg-shade-100"
                  >
                    {a.label}
                  </Button>
                ))}
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </span>
    </>
  );
}
