import {
  CreateUserOperationRequest,
  IWallpostCreate201Response,
  UserAdminAccessEnum,
} from "@apacta/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useAPI } from "~/lib/api";
import { useFormState } from "~/lib/form-state";
import { PhoneInput } from "~/lib/ui/phone-input";
import { addUserSchema } from "~/pages/employees/_cmp/add-user-schema";
import { Checkbox } from "~/lib/ui/form-elements/checkbox";
import { Suspense, useId, useState } from "react";
import { UsersPriceGroups } from "~/pages/employees/_cmp/users-price-groups";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { NumberInput } from "~/lib/ui/form-elements";
import { getIcon, LabelInput } from "~/lib/ui";
import { Spinner } from "~/lib/ui/spinner";
import { LabelsSelection } from "~/pages/employees/_cmp/labels-selection";
import { useMe } from "~/lib/auth/use-me";

export function CreateEmployeeDialog({
  onEmployeeCreated,
  onClose,
  firstName = "",
}: {
  onEmployeeCreated?: (userId: string) => void;
  onClose: () => void;
  firstName?: string;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  // Field inputs
  const {
    isValid,
    getValue,
    registerNumberInput,
    onChange,
    register,
    registerStringInput,
    setValues,
  } = useFormState({
    schema: addUserSchema,
    initialValues: {
      first_name: firstName,
      last_name: undefined,
      email: "",
      mobile_country_code: undefined,
      mobile_phone: "",
      admin_access: UserAdminAccessEnum.NoAccessToAdmin,
      users_price_group_id: undefined,
      cost_price: 0,
      extra_price: 0,
      labels: [],
    },
  });
  const manualCheckboxId = useId();
  const queryClient = useQueryClient();
  const [isUsingManualPrice, setIsUsingManualPrice] = useState(false);

  const me = useMe();
  const usedSeats = me?.subscription.activeUsers;
  const totalSeats = me?.subscription.totalSeats;

  const userCreate = useMutation({
    mutationFn: (args: CreateUserOperationRequest) => api.createUser(args),
    onSuccess: (data: IWallpostCreate201Response) => {
      if (!data.data.id) {
        console.warn("Employee created, but no id was returned", data.data);
        return;
      }
      queryClient.invalidateQueries({
        queryKey: ["users"], // this causes a refetch
      });
      onEmployeeCreated?.(data.data.id);
      onClose();
    },
  });

  function handleSubmit() {
    if (!isValid) {
      return;
    }

    userCreate.mutate({
      createUserRequest: {
        firstName: getValue("first_name"),
        lastName: getValue("last_name"),
        mobile: getValue("mobile_phone"),
        mobileCountrycode: getValue("mobile_country_code"),
        email: getValue("email"),
        costPrice: getValue("cost_price"),
        extraPrice: getValue("extra_price"),
        usersPriceGroupId: getValue("users_price_group_id"),
        manualPrice: isUsingManualPrice,
        labelIds: getValue("labels"),
      },
    });
  }

  return (
    <Suspense
      fallback={
        <div className="flex min-h-64 w-full items-center justify-center">
          <Spinner className="h-20 w-20" />
        </div>
      }
    >
      <form
        onSubmit={(e) => {
          console.log("submittttttt");
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="mb-12">
          <DialogHeader title={t("users:modal.create_user.title")} Icon={getIcon("add")} />
          <div className="mt-3 text-center sm:mt-5">
            <div className="mt-2">
              <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
                  {usedSeats} {t("common:out_of")} {totalSeats}{" "}
                  {t("users:seats_available", "seats available").toLowerCase()}
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <LabelInput
                    {...registerStringInput("first_name")}
                    label={t("users:first_name")}
                    required={true}
                  />
                  <LabelInput
                    {...registerStringInput("last_name")}
                    label={t("users:last_name")}
                    onSubmit={handleSubmit}
                  />
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <LabelInput
                    {...registerStringInput("email")}
                    label={t("customers:modal.create_customer.input.email")}
                    type="email"
                    required={true}
                  />
                  <PhoneInput
                    onChange={(phone) => {
                      setValues({ mobile_phone: phone.mobile, mobile_country_code: phone.code });
                    }}
                  ></PhoneInput>
                </div>
                <div className="grid grid-cols-4 gap-6">
                  <div className="col-span-2">
                    <UsersPriceGroups
                      onChange={(usersPriceGroup) => {
                        setValues({
                          cost_price: usersPriceGroup?.costPrice,
                          extra_price: usersPriceGroup?.extraPrice,
                          users_price_group_id: usersPriceGroup?.id,
                        });
                      }}
                      initialPriceGroupId={undefined}
                    />
                  </div>
                  <div className="mt-1">
                    <NumberInput
                      label={t("finance:hourly_price")}
                      {...registerNumberInput("cost_price")}
                      disabled={!isUsingManualPrice}
                    />
                  </div>
                  <div className="mt-1">
                    <NumberInput
                      label={t("finance:extra_price")}
                      {...registerNumberInput("extra_price")}
                      disabled={!isUsingManualPrice}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <LabelsSelection
                    defaultValues={getValue("labels")}
                    onChange={(labelIds) => {
                      setValues({ labels: labelIds });
                    }}
                  />
                </div>
                <div className="flex flex-col sm:flex-row">
                  <Checkbox
                    id={manualCheckboxId}
                    checked={isUsingManualPrice}
                    onChange={() => setIsUsingManualPrice(!isUsingManualPrice)}
                  />
                  <label htmlFor={manualCheckboxId}>{t("users:modal.manual_cost_price")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter
          onClose={onClose}
          primary={{
            onClick: handleSubmit,
            label: t("common:create", {
              replace: { entity: t("common:employee", { count: 1 }).toLocaleLowerCase() },
            }),
            disabled: !isValid,
            loading: userCreate.isPending,
          }}
        />
      </form>
    </Suspense>
  );
}
