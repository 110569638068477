import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { getIcon } from "~/lib/ui";
import { useCallback, useState } from "react";
import { useToasts } from "~/lib/toast/use-toasts";
import { LocationType, LocationView } from "~/lib/json-forms/components/location-view";
import { useMe } from "~/lib/auth/use-me";
import { useLocationIfAvailable } from "./use-location-if-available";
import { CheckTime } from "./check-time";
import Switch from "~/lib/ui/switch";

type PropsCommon = {
  onCustomerCreated?: (customerId: string) => void;
  onOpenChange?: (isOpen: boolean) => void;
  projectId: string;
  userCheckedIn: boolean;
  onClose: () => void;
};

export const CACHE_CHECKIN = "checkin";
export function CheckInDialog(props: PropsCommon) {
  const { t } = useTranslation();
  const api = useAPI();
  const me = useMe();
  const toast = useToasts();

  const [checkInTime, setCheckInTime] = useState<Date>();
  const [useCustomTime, setUseCustomTime] = useState(false);

  const location = useLocationIfAvailable();
  const disableSubmit = location === null || location === undefined;

  const handleCheckIn = useCallback(async () => {
    try {
      await api.checkIn({
        checkInRequest: {
          projectId: props.projectId,
          checkedIn: checkInTime,
          checkinLatitude: location?.latitude ? `${location.latitude}` : null,
          checkinLongitude: location?.longitude ? `${location.longitude}` : null,
        },
      });
      toast.showTemplate("CHANGES_SAVED");
      props.onClose();
    } catch (err) {
      toast.showTemplate("OPERATION_FAILED", {
        title: t("forms:check_in_error_title"),
        description: t("forms:check_in_error_description"),
      });
    }
  }, [checkInTime, location]);

  const handleCheckOut = useCallback(async () => {
    try {
      await api.checkOut({
        checkOutRequest: {
          checkedOut: checkInTime,
          checkoutLatitude: location?.latitude ? `${location.latitude}` : null,
          checkoutLongitude: location?.longitude ? `${location.longitude}` : null,
        },
      });
      toast.showTemplate("CHANGES_SAVED");
      props.onClose();
    } catch (err) {
      toast.showTemplate("OPERATION_FAILED", {
        title: t("forms:check_out_error_title"),
        description: t("forms:check_out_error_description"),
      });
    }
  }, [checkInTime, location]);

  return (
    <>
      <DialogHeader
        title={
          props.userCheckedIn
            ? t("common:confirm_check_out", "Confirm check out")
            : t("common:confirm_check_in", "Confirm check in")
        }
        Icon={getIcon("location")}
      />
      <LocationView
        loading={location === undefined}
        location={location as LocationType}
        label="location"
      />
      {me.companySettings.checkAtSpecificTime ? (
        <div className="mb-4">
          <Switch
            label={t("common:select_time_manually")}
            onCheckedChange={(v) => setUseCustomTime(v)}
            checked={useCustomTime}
          />
          {useCustomTime && (
            <>
              <CheckTime
                type={props.userCheckedIn ? "check-out" : "check-in"}
                onChange={(d) => setCheckInTime(d)}
              />
              <div className="text-gray-500">
                {props.userCheckedIn
                  ? t("common:select_past_time", "You can only select a time in the past")
                  : t("common:select_future_time", "You can only select a time in the future")}
              </div>
            </>
          )}
        </div>
      ) : null}
      <DialogFooter
        onClose={() => {
          props.onClose();
        }}
        primary={{
          label: props.userCheckedIn ? t("common:check_out") : t("common:check_in"),
          onClick: props.userCheckedIn ? handleCheckOut : handleCheckIn,
          disabled: disableSubmit,
        }}
      />
    </>
  );
}
