import { getSelectedIds, SelectionCombobox } from "./selection-combobox";
import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Dialog } from "..";
import { SelectionComboboxExtendable, ValueChangeHandler } from "./combobox-types";
import { ControlPanelApiProjectViewOrIndexResponse, IListProjectsRequest } from "@apacta/sdk";
import Badge from "../badge";
import { useTranslation } from "react-i18next";
import { CreateProjectDialog } from "~/pages/projects/_cmp/create-project-dialog";

type Entity = ControlPanelApiProjectViewOrIndexResponse; //shortened for readability

export function ProjectSelection<D extends Entity = Entity>({
  requestOverride,
  ...props
}: SelectionComboboxExtendable<D> & {
  requestOverride?: IListProjectsRequest;
  /** Called when the value is resolved to an entity */
  onInitialEntity?: (entity: D) => void;
}) {
  const { t } = useTranslation();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [createQuery, setCreateQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);

  const api = useAPI();
  const vcRef = useRef<ValueChangeHandler>(); // value change ref

  // Array of ids to select. Undefined if searchquery is set
  const idParam = getSelectedIds({
    value: props.value,
    defaultValue: props.defaultValue,
    isSearchDialogOpen,
  });

  const uq = useQuery({
    queryKey: ["projects", requestOverride, searchQuery, idParam],
    queryFn: () =>
      api.iListProjects({
        q: searchQuery,
        ids: idParam,
        projectStatus: ["open"], // Override if you need more
        ...requestOverride,
      }),
  });
  const items = uq.data?.data || [];

  const pendingSelection = useRef<string | undefined>();

  async function handleCreated(userId: string) {
    pendingSelection.current = userId;
    uq.refetch();
  }

  // Set the value after the data has been loaded
  useEffect(() => {
    if (pendingSelection.current === undefined) return;
    vcRef.current?.(pendingSelection.current);
    pendingSelection.current = undefined;
  }, [items]);

  // Trigger ONCE when the initial entity is resolved
  // E.g. prefilled projectId where you need to use project.projectType
  //  - but don't want to fetch it a second time
  const initialEntity = useRef<D | undefined>();
  useEffect(() => {
    if (initialEntity.current !== undefined) return;
    if (items.length === 1) {
      initialEntity.current = items[0] as D;
      props.onInitialEntity?.(items[0] as D);
    }
  }, [items]);

  return (
    <div>
      <SelectionCombobox<D>
        label={t("common:project", { count: 1 })}
        {...props}
        data={items as Array<D>}
        onQueryChange={setSearchQuery}
        onOpenChange={setIsSearchDialogOpen}
        valueFn={(entity) => entity.id}
        valueChangeRef={vcRef}
        labelFn={(entity) => entity.fullName} // searching
        renderItem={(entity) => (
          <div>
            {entity.projectNumber && (
              <Badge variant="gray" label={`#${entity.projectNumber}`} className="mr-2" />
            )}
            {entity.name}
          </div>
        )} // displaying
        loading={uq.isFetching}
        onCreateNew={(query) => {
          setCreateQuery(query);
          setIsCreateDialogOpen(true);
        }}
      />
      <Dialog
        open={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
        className="md:max-w-3xl"
        render={({ onClose }) => (
          <CreateProjectDialog
            onProjectCreated={(projectId) => {
              handleCreated(projectId);
              onClose();
            }}
            onOpenChange={() => onClose()}
            defaultName={createQuery}
          />
        )}
      />
    </div>
  );
}
