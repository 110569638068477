import { InvoiceActivityLogPopover } from "./activity-log-popover";
import { InvoiceActivityLogDetailTrigger } from "./activity-log-detail-trigger";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { twMerge } from "tailwind-merge";
import { useOutletContext } from "react-router";
import { InvoiceOutlet } from "../[invoiceId]";
import { useMount } from "~/lib/lifecycle-helpers";
import { CustomerDetailCard } from "./customer-detail-card";
import { InvoiceKPISection } from "./invoice-kpi-section";

export function InvoiceView() {
  const { t } = useTranslation();
  const { invoice, kpi } = useOutletContext<InvoiceOutlet>();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // Pre-fetch the PDF to check if it's available. Messy, but the only way atm
  useMount((signal) => {
    async function fetchPdf(s: AbortSignal) {
      try {
        if (!invoice.pdfUrl) return;
        const res = await fetch(invoice.pdfUrl, { signal: s });
        if (res.status !== 200) {
          setIsError(true);
          setLoading(false);
          return;
        }
      } catch (err) {
        // Ignore
      }
    }
    fetchPdf(signal);
  });

  return (
    <div className="flex flex-col">
      <InvoiceKPISection />

      <InvoiceActivityLogPopover
        invoiceId={invoice.id}
        trigger={<InvoiceActivityLogDetailTrigger invoiceId={invoice.id} />}
      />
      <h3 className="mt-4">{t("common:details")}</h3>

      {isError ? (
        <div className="rounded-md bg-red-200 p-4">{t("invoices:unexpected_error_pdf_embed")}</div>
      ) : (
        <div className="h-screen w-full rounded-md bg-white p-1">
          {loading && (
            <>
              <CustomerDetailCard customer={invoice.contact} showName />
              <SkeletonLoader template="document" className="h-screen p-4" />
            </>
          )}

          <embed
            src={invoice.pdfUrl}
            className={twMerge("h-screen w-full", (loading || isError) && "hidden")}
            onLoad={() => setLoading(false)}
          />
        </div>
      )}
    </div>
  );
}
