import { ComponentProps, useCallback, useState } from "react";
import { linkToFormNew, linkToProjectForm } from "../utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTypedSearchParams } from "../utils/use-typed-search-params";
import { useNavigate } from "react-router";
import { useMe } from "../auth/use-me";
import { useAPI } from "../api";
import { useTranslation } from "react-i18next";
import { DEV } from "../auth/config";
import { Button, Dialog, getIcon, Label } from "../ui";
import { FormViewWrapper } from "./FormViewWrapper";
import { EmployeeSelection } from "../ui/selection-combobox";
import { ProjectSelection } from "../ui/selection-combobox/project-selection";
import { FormTemplatePicker } from "~/pages/forms/_cmp/form-template-picker";
import { FormRenderer } from "./components/form-renderer";
import { UISchemaElement } from "@jsonforms/core";
import { ErrorObject } from "ajv";
import { useToastOnError } from "../utils/hooks";
import { ResponseError } from "@apacta/sdk";
import { useToasts } from "../toast/use-toasts";
import { CACHE_PROJECTS } from "~/pages/projects";
import { CACHE_CHECKIN, CheckInDialog } from "~/pages/time-registration/_cmp/check-in-dialog";

/**
 * An abstraction to standardize the way forms are rendered in the control panel.
 * Used in Projects as a sub-page, but as a standalone page in other contexts.
 *
 * In stand-alone it wraps a PageLayout, otherwise uses TabHeadings
 */
export function FormCreateView(props: {
  mode: ComponentProps<typeof FormViewWrapper>["mode"];
  projectId?: string;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToasts();
  const api = useAPI();
  const me = useMe();
  const navigate = useNavigate();
  const [params, setParams] = useTypedSearchParams<{
    template?: string;
    employee?: string;
    project?: string;
  }>();

  const [data, setData] = useState<unknown>({}); // We don't know how this looks at compile time.
  const [showData, setShowData] = useState(false);
  const [backendErrors, setBackendErrors] = useState<Array<ErrorObject>>([]);

  const templateQ = useQuery({
    queryKey: ["formTemplates", params.template, props.projectId],
    queryFn: () =>
      api.formTemplateView({
        formTemplateId: params.template as string,
        projectId: props.projectId,
      }),
    enabled: Boolean(params.template),
  });
  useToastOnError(templateQ.error);

  const userCheckedInQuery = useQuery({
    queryKey: [CACHE_CHECKIN],
    queryFn: () => api.isCheckedIn({}),
  });
  const isUserCheckedIn = userCheckedInQuery?.data?.data.checkedIn ?? false;
  const createM = useMutation({
    mutationKey: ["formTemplates", params.template],
    mutationFn: (args: { formTemplateId: string; data: unknown }) =>
      api.createForm({
        createFormRequest: {
          formTemplateId: args.formTemplateId,
          fields: args.data as object,
          projectId: selectedProjectId,
          userId: selectedEmployeeId,
        },
      }),
    onSuccess: (newForm) => {
      if (!newForm.data.id) throw new Error("Form creation failed");
      // We don't want the back button to go back to the pre-submitted form
      const navOpts = { replace: true };
      queryClient.invalidateQueries({
        queryKey: [CACHE_PROJECTS, selectedProjectId],
      });

      if (props.mode === "project") {
        navigate(linkToProjectForm(selectedProjectId as string, newForm.data.id), navOpts);
        return;
      }
      navigate(linkToFormNew(newForm.data.id), navOpts);
    },
    onError: async (error) => {
      if (error instanceof ResponseError && error.response.status === 422) {
        const err = await error.response.json();
        if (err.errors && Array.isArray(err.errors)) {
          setBackendErrors(err.errors);
        }
      }
      toast.showTemplate("UNEXPECTED_ERROR");
    },
  });

  const jsonForm = templateQ.data?.data;

  const handleCreateClick = useCallback(() => {
    createM.mutate({
      formTemplateId: params.template as string,
      data: data,
    });
  }, [data, params.template]);

  // We need a project, employee and a template to create a form
  const selectedEmployeeId = params.employee ?? me.user.id;
  const selectedProjectId = params.project ?? props.projectId;
  const isCreationPossible = Boolean(jsonForm && selectedProjectId && selectedEmployeeId);

  const title = jsonForm?.name ?? t("forms:new_form", "New form");
  // Logged-in user is same as selected employee
  const isItMe = me.user.id === selectedEmployeeId;
  const unableToCheckIn = Boolean(!isItMe || !selectedProjectId || !selectedEmployeeId);

  return (
    <FormViewWrapper
      mode={props.mode}
      title={title}
      actions={
        <div className="flex gap-2">
          {DEV && (
            <Button variant="secondary" onClick={() => setShowData((prev) => !prev)}>
              Data
            </Button>
          )}
          <Dialog
            trigger={
              <Button variant="secondary" disabled={unableToCheckIn}>
                {isUserCheckedIn
                  ? t("common:check_out", "Check out")
                  : t("common:check_in", "Check in")}
              </Button>
            }
            render={({ onClose }) => (
              <CheckInDialog
                onClose={() => {
                  queryClient.invalidateQueries({ queryKey: [CACHE_CHECKIN] });
                  onClose();
                }}
                projectId={selectedProjectId as string}
                userCheckedIn={isUserCheckedIn}
              />
            )}
          ></Dialog>
          {isCreationPossible ? (
            <Button variant="tertiary" onClick={handleCreateClick} Icon={getIcon("add")}>
              {t("common:create", {
                replace: { entity: t("common:form", { count: 1 }).toLocaleLowerCase() },
              })}
            </Button>
          ) : null}
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        {/* Employee Selection */}
        <EmployeeSelection
          required
          label={t("common:employee", { count: 1 })}
          onSelect={(val) => !!val && setParams("employee", val, { replace: false })}
          defaultValue={selectedEmployeeId}
          readonly={me.user.adminAccess !== "access_to_admin"}
        />
        {/* Project Selection */}
        {props.mode === "project" ? null : (
          <ProjectSelection
            required
            defaultValue={params.project}
            onSelect={(val) => setParams("project", val ?? undefined)}
          />
        )}
        {/* Template Selection */}
        <div>
          <Label required>{t("forms:template", "Template")}</Label>
          {!params.template ? (
            <div className="mb-8">
              <FormTemplatePicker
                disabled={!selectedProjectId}
                onFormChanged={(template) => setParams("template", template.id)}
              />
            </div>
          ) : null}
        </div>
        {templateQ.error && (
          <div className="text-red-500">
            {t("forms:template_error", "Failed to load form template")}
          </div>
        )}
        {jsonForm && (
          <FormRenderer
            mode="create"
            schema={jsonForm.fields.schema}
            uischema={jsonForm.fields.uiSchema as UISchemaElement}
            data={data}
            onChange={({ data: formData }) => setData(formData)}
            additionalErrors={backendErrors}
          />
        )}
      </div>
      {jsonForm && showData && (
        <div className="fixed left-0 top-0 z-50 h-full w-full overflow-scroll bg-white p-4">
          <pre>{JSON.stringify(data, null, 2)}</pre>
          <button onClick={() => setShowData(false)} className="absolute right-0 top-0 p-4">
            Close
          </button>
        </div>
      )}
      <div className="mt-2 flex justify-end">
        {isCreationPossible ? (
          <Button variant="tertiary" onClick={handleCreateClick} Icon={getIcon("add")}>
            {t("common:create", {
              replace: { entity: t("common:form", { count: 1 }).toLocaleLowerCase() },
            })}
          </Button>
        ) : null}
      </div>
    </FormViewWrapper>
  );
}
