import { ChangeStatusOperationRequest, FrontpageFeedDTO } from "@apacta/sdk";
import UserIdAvatar from "~/lib/ui/avatar/user-id-avatar";
import { NavLink } from "react-router-dom";
import { linkToEmployee, linkToProject } from "~/lib/utils";
import { Card, CardField } from "../static/card";
import { CardHeader } from "../static/card-header";
import { useTranslation } from "react-i18next";
import { padTimeStrings, timeBetween } from "~/lib/utils/date/date-utils";
import { Button } from "~/lib/ui";
import { useAPI } from "~/lib/api";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import Switch from "~/lib/ui/switch";

export const FormCardTimeRegistration = ({ data }: { data: FrontpageFeedDTO }) => {
  const [localData, setLocalData] = useState<FrontpageFeedDTO>(data);
  const [pdfPreviewOpen, setPdfPreviewOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const api = useAPI();

  const changeStatusM = useMutation({
    mutationFn: (args: ChangeStatusOperationRequest) => api.changeStatus(args),
    onSettled: (res, err, args) => {
      setLocalData((prevState) => {
        return { ...prevState, approved: args.changeStatusRequest?.approve ?? false };
      });
    },
  });

  async function handleApproveToggle(currentStatus: boolean) {
    return changeStatusM.mutateAsync({
      projectId: localData.project?.id as string,
      changeStatusRequest: {
        approve: !currentStatus,
        forms: [localData.form?.id as string],
      },
    });
  }

  const files = localData.formFields?.images ?? [];
  const fields: Array<CardField> = [
    {
      label: t("common:project", { count: 1 }),
      render: () => (
        <NavLink to={linkToProject(localData.project?.id ?? "")} className="hover:underline">
          {localData.project?.fullName}
        </NavLink>
      ),
    },
    {
      label: t("common:employee", { count: 1 }),
      render: () => (
        <NavLink to={linkToEmployee(localData.employee.id ?? "")} className="hover:underline">
          <div className="flex">{`${localData.employee.firstName} ${localData.employee.lastName}`}</div>
        </NavLink>
      ),
    },
    {
      label: t("common:pdf"),
      render: () =>
        data.form?.linkToPdf ? (
          <>
            <Button onClick={() => setPdfPreviewOpen(true)} variant="secondary" size="small">
              {`${t("common:show")} ${t("common:pdf")}`}
            </Button>
            <FullScreenFilePreview
              open={pdfPreviewOpen}
              onClose={() => setPdfPreviewOpen(false)}
              fileUrl={data.form?.linkToPdf}
            />
          </>
        ) : (
          <span className="text-shade-400">{t("common:not_available")}</span>
        ),
    },
    {
      label: t("common:time_registration"),
      render: () =>
        localData.formFields?.timepicker ??
        (localData.formFields?.timeIntervalFrom && localData.formFields?.timeIntervalTo
          ? timeBetween(
              padTimeStrings(localData.formFields?.timeIntervalFrom),
              padTimeStrings(localData.formFields?.timeIntervalTo)
            )
          : "00:00"),
    },
    {
      label: t("common:time_interval"),
      render: () =>
        localData.formFields?.timeIntervalFrom && localData.formFields?.timeIntervalTo ? (
          <div>{`${padTimeStrings(localData.formFields?.timeIntervalFrom)} - ${padTimeStrings(localData.formFields?.timeIntervalTo)}`}</div>
        ) : (
          <div className="text-gray-400">{t("common:not_available")}</div>
        ),
    },
    {
      label: t("common:approved"),
      render: () => (
        <div className="flex items-center gap-2">
          <Switch
            defaultChecked={localData.approved}
            onCheckedChange={() => handleApproveToggle(localData.approved ?? false)}
          />
        </div>
      ),
    },
  ];

  return (
    <Card
      renderHeader={() => (
        <CardHeader
          title={localData.template ?? t("common:form")}
          icon="hours"
          renderIdentifier={() => (
            <UserIdAvatar
              userId={localData.employee.id}
              className="flex h-7 w-7 items-center justify-center text-xs"
            />
          )}
        />
      )}
      fields={fields}
      files={files}
    />
  );
};
