import SidePanel from "~/lib/ui/side-panel";
import { Card, CardAndTaskMutationArgs, Task, usePlanning } from "~/lib/planning";
import { useTranslation } from "react-i18next";
import { useFormState } from "~/lib/form-state";
import { defaultTaskFormState, taskFormStateSchema } from "./state/task-form-state";
import { useAPI } from "~/lib/api";
import { notNullOrUndefinedFilter, onlyUniqueFilter } from "~/lib/utils/filters";
import { dateIsWeekend, matchDates } from "~/lib/utils/date/date-utils";
import { useEffect, useState } from "react";
import { Spinner } from "~/lib/ui/spinner";
import useCards from "~/lib/planning/use-cards";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { ProjectSection } from "~/pages/planning/_cmp/crud/sections";
import { useCategoryLabels } from "~/lib/ui/category-label/use-category-labels";
import { Project } from "@apacta/sdk";
import { PlanningTabs } from "~/pages/planning/_cmp/crud/tabs/planning-tabs";

export function EditTaskPanel({
  open,
  onClose,
  onSubmit,
  onDelete,
  card,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: ({ type }: CardAndTaskMutationArgs) => void;
  onDelete: (card: Card) => void;
  card?: Card;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const { taskLabels } = useCategoryLabels();
  const { cardGetTask } = usePlanning();
  const { cardAction } = useCards();

  const [loading, setLoading] = useState<boolean>(true);

  const formState: TaskFormState = useFormState({
    schema: taskFormStateSchema,
    initialValues: defaultTaskFormState,
  });

  const [task, setTask] = useState<Task | null | undefined>();
  const [project, setProject] = useState<Project | undefined>();

  /**
   * Fetch the task and card data.
   * This is used to check if we have multiple cards associated with the same task.
   * If we do, we need to display the correct data when opening any of the cards.
   */
  useEffect(() => {
    if (!card) return;
    setLoading(true);

    // Fetch the task data and set it in state
    const localTask = card ? cardGetTask({ card }) : null;
    setTask(localTask);

    if (localTask?.id) {
      // We need to fetch the data from the task, to get all the associated cards etc.
      api
        .iGetTask({ taskId: localTask.id }, {})
        .then((res) => {
          // All cards associated with task
          const cards = res.data?.cards || [];
          const fetchedTask = res.data as Task;

          // The project associated with the task
          const localProject = res.data?.project;
          setProject(localProject);

          if (cards.length > 0) {
            // Filter the cards to only get the ones with a date
            const d = cards
              .map((c) => c.date)
              .filter(notNullOrUndefinedFilter)
              .filter(onlyUniqueFilter);

            const firstDate: Date | null =
              d.sort((a: Date, b: Date) => a.getTime() - b.getTime())[0] ?? null;
            const lastDate: Date | null =
              d.sort((a: Date, b: Date) => b.getTime() - a.getTime())[0] ?? null;

            const fromDate: Date | null = firstDate;
            let toDate = null;

            if (firstDate && lastDate && !fetchedTask.repeat) {
              toDate = matchDates(firstDate, lastDate, { ignoreTimestamp: true }) ? null : lastDate;
            }

            const state = {
              fromDate,
              toDate,
              includeWeekends: d.filter((date) => dateIsWeekend(date)).length > 0,
              startTime: {
                hour: card?.startTime?.getHours() ?? null,
                minute: card?.startTime?.getMinutes() ?? null,
              },
              duration: card?.estimate ?? null,
              employeeIds: cards
                .map((c) => c.userId)
                .concat([card?.userId ?? null])
                .filter(notNullOrUndefinedFilter)
                .filter(onlyUniqueFilter)
                .sort(),
              labelId: fetchedTask.labels?.[0]?.id ?? null,
              projectId: fetchedTask.projectId ?? null,
              repeat: fetchedTask.repeat,
              interval: fetchedTask.interval,
              endTime: fetchedTask.endTime,
              description: fetchedTask.description ?? "",
              taskName: fetchedTask.name,
              products: fetchedTask.products,
              status: card?.status ?? "to_do",
              formTemplateIds: fetchedTask.formTemplates?.map((f) => f.id!) ?? [],
            };
            formState.setValues(state, true);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [card]);

  const saveChanges = async () => {
    // Save changes
    if (!card) return;

    const updateResults = await cardAction({
      action: "UPDATE",
      card,
      formState,
      data: {
        selectedLabel: taskLabels.find((l) => l.id === formState.getValue("labelId")) ?? null,
      },
    });
    if (updateResults) {
      onSubmit(updateResults);
    }
  };

  return (
    <SidePanel
      open={open}
      onClose={() => onClose()}
      config={{
        width: "w-full",
        maxWidth: "max-w-2xl",
      }}
      header={
        !loading ? (
          <div className="px-6">
            <ProjectSection project={project} formState={formState} />
          </div>
        ) : null
      }
      actionButtons={[
        [
          {
            text: t("common:save_changes"),
            variant: "tertiary",
            onClick: () => saveChanges(),
            disabled: !formState.isValid || !formState.isModified,
            className: "px-3",
          },
          {
            text: t("common:cancel"),
            onClick: () => onClose(),
            className: "px-3 hover:underline",
          },
        ],
        [
          {
            text: t("common:delete_entity", { entity: t("common:task").toLowerCase() }),
            variant: "primary",
            onClick: () => (card ? onDelete(card) : undefined),
            className: "px-3 self-end",
          },
        ],
      ]}
    >
      {open && (
        <div className="planning-scrollbar flex h-full flex-col gap-2 overflow-y-auto">
          {loading ? (
            <div className="flex h-full w-full items-center justify-center">
              <Spinner className="h-auto w-12" />
            </div>
          ) : (
            <div className="px-6">
              <PlanningTabs
                formState={formState}
                taskId={task?.id}
                repeatInfo={{
                  isInfiniteRepeat: !!task?.repeat && !task?.endTime,
                  endTime: task?.endTime,
                }}
                isEditPanel={true}
              />
            </div>
          )}
        </div>
      )}
    </SidePanel>
  );
}
