import { getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";

async function slowPromise() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, 1000);
  });
}

export default function ActionButtonPlayground() {
  return (
    <ActionButtons
      actions={[
        {
          label: "Action 1",
          onClick: () => slowPromise(),
          Icon: getIcon("add"),
          confirm: {
            action: "generic",
          },
        },
        {
          label: "Action 2",
          onClick: () => slowPromise(),
          Icon: getIcon("add"),
        },
        {
          label: "Action 3",
          onClick: () => slowPromise(),
          Icon: getIcon("add"),
          collapseBehavior: "always",
        },
      ]}
    />
  );
}
