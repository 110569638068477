import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
  Ref,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import {
  ButtonSizes,
  ButtonVariant,
  getButtonClasses,
  getButtonIconClass,
} from "./shared/button.shared";
import { IconProp } from "../types";
import { Spinner } from "../spinner";
import { ConfirmationDialog, Dialog } from "../dialog";
import { Link } from "react-router-dom";
import {
  entityConfirmationTemplate,
  ConfirmationTemplateProps,
} from "../dialog/templates/confirmation-templates";
import { useTranslation } from "react-i18next";

// Used to customize the confirmation dialog
export type ButtonConfirmOptions = ConfirmationTemplateProps;

export const Button = forwardRef(function ButtonInner(
  props: {
    children?: ReactNode;
    variant?: ButtonVariant;
    size?: ButtonSizes;
    disabled?: boolean;
    className?: string;
    /**
     * Note: Icon should be on the left side. Chevron and the like should be built on top of this instead of adding
     */
    Icon?: IconProp;
    iconClassName?: string;
    loading?: boolean;
    confirm?: ButtonConfirmOptions;
    href?: string; // Wraps the button in a Link
    target?: string; // Tells the link to open in a new window, if any
  } & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  forwardedRef: Ref<HTMLButtonElement>
) {
  const {
    variant,
    className,
    Icon,
    children,
    iconClassName,
    onClick,
    loading,
    size,
    confirm,
    href,
    target,
    ...restProps
  } = props;
  const baseIconClass = getButtonIconClass({ size });
  const [internalLoading, setInternalLoading] = useState(false);
  const { t } = useTranslation();

  async function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (onClick) {
      setInternalLoading(true);
      // This await a promise (or creates one), then set loading to false regardless of outcome
      await Promise.resolve(onClick(e)).finally(() => {
        setInternalLoading(false);
      });
    } else {
      console.warn("No onClick handler provided for Button component");
    }
  }

  const isLoading = loading ?? internalLoading;

  const buttonFragment = (
    <button
      className={twMerge(
        getButtonClasses({ variant, disabled: restProps.disabled || isLoading, size }),
        className
      )}
      type={restProps.type || "button"}
      ref={forwardedRef}
      onClick={confirm ? undefined : handleClick}
      {...restProps}
      disabled={restProps.disabled || isLoading}
    >
      {isLoading && (
        <Spinner className={twMerge(baseIconClass, children && "mr-3", iconClassName)} />
      )}
      {Icon && !isLoading && (
        <Icon className={twMerge(baseIconClass, children && "mr-3", iconClassName)} />
      )}
      {children && <>{children}</>}
    </button>
  );

  if (href !== undefined) {
    return (
      <Link to={href} target={target}>
        {buttonFragment}
      </Link>
    );
  }

  if (confirm !== undefined) {
    return (
      <Dialog
        trigger={buttonFragment}
        render={({ onClose }) => (
          <ConfirmationDialog
            // If confirmation template is provided, apply it
            {...(confirm !== undefined ? entityConfirmationTemplate({ ...confirm, t }) : {})}
            onClose={onClose}
            onSubmit={handleClick}
          />
        )}
      />
    );
  }

  return buttonFragment;
});
