import { useTranslation } from "react-i18next";
import { Label } from "~/lib/ui";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { CheckboxLine } from "~/lib/ui/form-elements/checkbox-line";
import { z } from "zod";
import { useFormState } from "~/lib/form-state";
import {
  ControlPanelApiProjectViewOrIndexResponse,
  CreateInvoiceOperationRequest,
} from "@apacta/sdk";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { startOfDay, endOfDay } from "date-fns";

export function CreateInvoiceHourly({
  onSubmit,
  onClose,
  projectId,
  fromDate,
  toDate,
  project,
}: {
  onSubmit: (request: CreateInvoiceOperationRequest) => void;
  onClose: () => void;
  projectId: string;
  fromDate?: Date;
  project: ControlPanelApiProjectViewOrIndexResponse;
  toDate?: Date;
}) {
  const { t } = useTranslation();
  const api = useAPI();

  const invoices = useSuspenseQuery({
    queryKey: ["invoices", "project", projectId],
    queryFn: () =>
      api.iListInvoices({
        projectId: projectId,
        sort: "date",
        direction: "desc",
      }),
  });

  // Business rules
  const fromDateB = (() => {
    // 1. If project has no invoices, default to the project date
    if (invoices.data.data.length === 0) return startOfDay(project.created);
    // 2. If project has invoices, default to the last invoice date
    return startOfDay(invoices.data?.data[0]?.created);
  })();

  const formState = useFormState({
    schema: {
      fromDate: z.date(),
      toDate: z.date(),
      includeInvoicedForms: z.boolean().optional(),
      combineProductLines: z.boolean().optional(),
      combineWorkingTimeLines: z.boolean().optional(),
      markInvoiced: z.boolean().optional(),
      viewFormsPerEmployeePerDay: z.boolean().optional(),
    },
    initialValues: {
      fromDate: fromDate ?? fromDateB ?? startOfDay(new Date()),
      toDate: toDate ?? endOfDay(new Date()),
      includeInvoicedForms: false,
      combineProductLines: false,
      combineWorkingTimeLines: false,
      markInvoiced: true,
      viewFormsPerEmployeePerDay: false,
    },
  });

  const values = formState.getValues();

  return (
    <div>
      <Label>{t("common:period", "Period")}</Label>
      <DateRangePopover
        dateFrom={values.fromDate}
        dateTo={values.toDate}
        onChange={(range) => {
          if (!range.from || !range.to) return;
          formState.setValues({
            fromDate: range.from,
            toDate: range.to,
          });
        }}
      />
      <div className="my-4 flex flex-col gap-2">
        <CheckboxLine
          label={t("invoices:include_invoiced_forms", "Include invoiced forms")}
          checked={values.includeInvoicedForms}
          onChange={(e) => formState.onChange("includeInvoicedForms", e.currentTarget.checked)}
        />
        <CheckboxLine
          label={t("invoices:combine_product_lines", "Collect all products in one line")}
          checked={values.combineProductLines}
          onChange={(e) => formState.onChange("combineProductLines", e.currentTarget.checked)}
        />
        <CheckboxLine
          label={t("invoices:combine_working_time_lines", "Collect all hours in one line")}
          checked={values.combineWorkingTimeLines}
          onChange={(e) => formState.onChange("combineWorkingTimeLines", e.currentTarget.checked)}
        />
        <CheckboxLine
          label={t("invoices:view_forms_per_employee_per_day", "View forms per employee per day")}
          checked={values.viewFormsPerEmployeePerDay}
          onChange={(e) =>
            formState.onChange("viewFormsPerEmployeePerDay", e.currentTarget.checked)
          }
        />
        <CheckboxLine
          label={t(
            "invoices:mark_hours_and_products_as_invoiced",
            "Mark hours and materials as invoiced"
          )}
          checked={values.markInvoiced}
          onChange={(e) => formState.onChange("markInvoiced", e.currentTarget.checked)}
        />
      </div>
      <DialogFooter
        onClose={onClose}
        primary={{
          label: t("common:create", {
            defaultValue: "Create {{entity}}",
            replace: { entity: t("common:invoice", { count: 1 }).toLocaleLowerCase() },
          }),
          onClick: () =>
            onSubmit({
              createInvoiceRequest: {
                projectId: projectId,
                dateFrom: values.fromDate,
                dateTo: values.toDate,
                combineProductLines: values.combineProductLines,
                combineWorkingTimeLines: values.combineWorkingTimeLines,
                includeInvoicedForms: values.includeInvoicedForms,
                markInvoiced: values.markInvoiced,
                groupByForms: values.viewFormsPerEmployeePerDay,
              },
            }),
        }}
      />
    </div>
  );
}
