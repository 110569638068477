import { useNavigate, useParams } from "react-router";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useAPI } from "~/lib/api";
import { useQuery, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { PageLayout } from "~/lib/ui/page-layout";
import { useOfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";
import OfferStatusLabel from "~/lib/offers/offer-status-label";
import { BaseFile, ContactPerson, StandardOfferFileTypeEnum } from "@apacta/sdk";
import ContentLoader from "react-content-loader";
import HeaderSection from "~/pages/offers/_cmp/header-section";
import DescriptionSection from "~/pages/offers/_cmp/description-section";
import { OrderLinesBuilderRef } from "~/lib/ui/order-lines/lib/types";
import { OrderLinesBuilder } from "~/lib/ui/order-lines/order-lines-builder";
import { offerLineToOrderLineTransformer } from "~/lib/ui/order-lines/lib/transformers";
import OrderLinesBuilderProvider from "~/lib/ui/order-lines/order-lines-builder-context";
import { usePdfBuilder } from "~/lib/pdf/use-pdf-builder";
import { useContacts } from "~/lib/ui/contacts/use-contacts";
import { useMount } from "~/lib/lifecycle-helpers";
import { twMerge } from "tailwind-merge";
import OfferViewPageActions from "~/pages/offers/_cmp/offer-view-page-actions";
import OfferViewTitleSection from "~/pages/offers/_cmp/offer-view-title-section";
import {
  OfferLinesRuleSelector,
  OfferLinesRuleSelectorRef,
} from "~/pages/offers/_cmp/offer-lines-rule-selector";
import { useToastOnError } from "~/lib/utils/hooks";
import { runOfferLineRule } from "~/pages/offers/_cmp/helpers/run-offer-line-rules";
import { useOfferStatus } from "~/lib/offers/use-offer-status";
import { Customer } from "~/lib/customer/types";
import OfferViewFileSection from "~/pages/offers/_cmp/offer-view-file-section";
import { useTranslation } from "react-i18next";
import Tooltip from "~/lib/ui/tooltip";
import { ExclamationTriangleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import KpiSection from "~/pages/offers/_cmp/kpi-section";
import ManualPricingSection from "~/pages/offers/_cmp/manual-pricing-section";
import { StandardFilesCacheKey } from "~/pages/settings/index/files";
import { Dialog } from "~/lib/ui";
import { ConfirmationDialog } from "~/lib/ui/dialog";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import { ActivityLogSection } from "~/pages/offers/_cmp/activity-log-section";
import { useMe } from "~/lib/auth/use-me";

export const CACHE_OFFER = "offer";
export default function OfferViewPage() {
  const navigate = useNavigate();
  const api = useAPI();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { id: offerId } = useParams();

  const { contentRef, downloadPdf, previewPdf, getPdf, generateDocument } = usePdfBuilder();
  const { contact, setContact, contactPerson, setContactPerson } = useContacts();
  const pageTitle = usePageTitle();

  const me = useMe();

  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [previewHTML, setPreviewHTML] = useState<string | null>(null);
  const [filesToUpload, setFilesToUpload] = useState<Array<File>>([]);
  const [filesToDelete, setFilesToDelete] = useState<Array<BaseFile>>([]);
  const [existingFiles, setExistingFiles] = useState<Array<BaseFile>>([]);
  const [stagedStandardFile, setStagedStandardFile] = useState<BaseFile | null>(null);
  const [standardFileDeleteAlertOpen, setStandardFileDeleteAlertOpen] = useState<boolean>(false);

  const distributionRuleRef = useRef<OfferLinesRuleSelectorRef>(null);

  const handleSelectCustomer = (c?: Customer | null) => {
    if (c !== contact) {
      formState.setValues({ contactId: c?.id, contactPersonId: null });

      handleSetWorkingAddress(c);

      setContact(c);
      setContactPerson(null);
    }
  };

  const handleSetWorkingAddress = (c?: Customer | null) => {
    if (!c) return;

    const workingAddress = formState.getValue("streetName");

    // Unless the user has already changed the working address, we set it to the customer's address
    if (!workingAddress) {
      formState.setValues({ streetName: c.address ?? undefined });
      formState.setValues({ zipCode: c.zipCode ?? undefined });
      formState.setValues({ cityId: c.cityId ?? undefined });
      formState.setValues({ cityName: c.cityName ?? undefined });
    }
  };

  const handleSelectContactPerson = (c?: ContactPerson | null) => {
    if (c !== contactPerson) {
      formState.setValues({ contactPersonId: c?.id });
      setContactPerson(c);
    }
  };

  const { data, error } = useSuspenseQuery({
    queryKey: [CACHE_OFFER, offerId],
    queryFn: () => api.offersView({ offerId: offerId as string }),
  });

  const {
    data: fileData,
    isFetching: isFetchingFiles,
    error: fileError,
  } = useSuspenseQuery({
    queryKey: ["offer-files", offerId],
    queryFn: () => api.getOfferFiles({ offerId: offerId as string }),
  });

  useToastOnError(error);

  const offer = data?.data;
  const offerFiles = fileData?.data;

  const { offerStatus } = useOfferStatus(offer?.offerStatusId);

  useMount(() => {
    if (offer?.title) {
      pageTitle.set(`${offer.title} | ${t("navigation:offers.title")}`);
    }
    if (offer?.contact) {
      setContact(offer.contact);
    }

    if (offer?.contactPerson) {
      setContactPerson(offer.contactPerson);
    }

    if (offerFiles) {
      setExistingFiles(offerFiles);
    }
  });

  useEffect(() => {
    setExistingFiles(offerFiles);
  }, [offerFiles]);

  // We can do this, because we have suspense enabled
  const { formState, getOfferData, setInitialValues } = useOfferFormState(offer);

  const offerNumber = offer?.offerNumber;
  const orderLinesBuilderRef = useRef<OrderLinesBuilderRef>(null);
  const [linesModified, setLinesModified] = useState<boolean>(false);
  const [editorValidState, setEditorValidState] = useState<boolean>(true);

  const registerModified = () => {
    if (orderLinesBuilderRef.current) {
      setLinesModified(orderLinesBuilderRef.current.isModified());
    }
  };

  const handleDownloadPdf = async () => {
    await runOfferLineRule(offer, orderLinesBuilderRef, formState, api);
    await downloadPdf();
  };

  const handleSelectFiles = (files: FileList) => {
    setFilesToUpload((prevState) => [...prevState, ...Array.from(files)]);
  };

  const handleRemoveFile = (file: File | BaseFile | null, confirmed?: boolean) => {
    if (!file) return;

    // If the file is an existing file, we need to add it to the delete list, otherwise just remove it from the files to be uploaded
    if ((file as BaseFile).id) {
      if ((file as BaseFile).isStandardFile && !confirmed) {
        setStandardFileDeleteAlertOpen(true);
        setStagedStandardFile(file as BaseFile);
        return;
      }
      setStandardFileDeleteAlertOpen(false);
      setStagedStandardFile(null);
      setFilesToDelete((prevState) => [...prevState, file as BaseFile]);
    } else {
      setFilesToUpload((prevState) => prevState.filter((f) => f !== file));
    }
  };

  const files: Array<File | BaseFile> = useMemo(() => {
    const filteredExisting = existingFiles.filter((f) => !filesToDelete.includes(f));
    return [...filteredExisting, ...filesToUpload].sort((a, b) => a.name.localeCompare(b.name));
  }, [filesToUpload, filesToDelete, existingFiles]);

  const invalidateFiles = async () => {
    await queryClient.invalidateQueries({ queryKey: ["offer-files", offerId] });
    setFilesToUpload([]);
    setFilesToDelete([]);
  };

  const isLocked =
    offerStatus?.identifier === "accepted" ||
    offerStatus?.identifier === "sent" ||
    offerStatus?.identifier === "deleted";

  const canSetManualPrice = [
    "gather_offer_lines",
    "show_only_product_bundles",
    "total_price_line",
  ].includes(formState.getValue("offerLinesRule") ?? "");

  const standardFiles = useQuery({
    queryKey: [StandardFilesCacheKey],
    queryFn: () => api.iStandardOfferFilesList({}),
  });

  /**
   * Handle setting of standard files based on the type change
   * @param type - The type of offer to switch to
   */
  const handleTypeChange = async (type: StandardOfferFileTypeEnum) => {
    // Always refetch files, as we need to make sure we have the latest data
    await standardFiles.refetch();

    // Get the standard files that correspond to the selected type
    const filteredStandardFiles = standardFiles.data?.data.filter((f) => f.type === type);

    const newStandardFiles: Array<BaseFile> = [];
    const existingStandardFiles: Array<BaseFile> = [];

    // If there are standard files for the selected type, we need to add them
    if (filteredStandardFiles && filteredStandardFiles.length) {
      // Find existing standard files
      existingStandardFiles.push(...existingFiles.filter((f) => f.isStandardFile));

      // Get existing standard files IDs
      const existingStandardFileIds = existingStandardFiles.map((f) => f.id);

      // If all the standard files are already attached, we don't need to do anything except actually switching offer type
      if (filteredStandardFiles.every((f) => existingStandardFileIds.includes(f.file.id))) {
        formState.setValues({ type });
        return;
      }

      // We need to build the BaseFile objects for the new standard files
      newStandardFiles.push(
        ...filteredStandardFiles.map((f) => {
          return {
            ...f.file,
            isStandardFile: true,
            standardOfferFileId: f.id,
          } as BaseFile;
        })
      );
    }

    // Set the new files directly in the existing files state. The reason for this is to avoid the upload state being triggered
    // If we don't do this, the files will be downloaded and uploaded again, which is a waste of bandwidth
    // The backend will handle the actual attachment of the files
    setExistingFiles((prevState) => [
      ...prevState.filter((f) => !f.isStandardFile),
      ...newStandardFiles,
    ]);

    // Finally, set the new type on the offer
    formState.setValues({ type });
  };

  return (
    <Suspense>
      <Dialog
        open={standardFileDeleteAlertOpen}
        onOpenChange={() => setStandardFileDeleteAlertOpen(false)}
        render={({ onClose }) => (
          <ConfirmationDialog
            title={t("offers:delete_standard_file_title", {
              defaultValue: "Standard file deletion",
            })}
            description={t("offers:delete_standard_file_description", {
              defaultValue:
                "You're about to delete a file that was automatically attached to this offer. Are you sure you want to delete it?",
            })}
            variant="warning"
            submitLabel={t("common:confirm")}
            Icon={ExclamationTriangleIcon}
            onSubmit={() => handleRemoveFile(stagedStandardFile, true)}
            onClose={() => onClose()}
          />
        )}
      />

      {offer && (
        <PageLayout
          title={offer?.title || ""}
          onBackClick={() => navigate(-1)}
          developerActions={[
            {
              label: "Open public page", // developer action, no need for translation
              action: () =>
                window.open(`http://localhost:3000/p/offer/${offer.id}/${offer.token}`, "_blank"),
            },
            {
              label: "Generate document", // developer action, no need for translation
              action: () => generateDocument(),
            },
          ]}
          renderDescription={() => (
            <OfferStatusLabel selectedStatusId={offer?.offerStatusId} readonly />
          )}
          renderActions={() => (
            <OfferViewPageActions
              editMode={!isLocked}
              isAccepted={offerStatus?.identifier === "accepted"}
              isRejected={offerStatus?.identifier === "rejected"}
              offer={offer}
              formState={formState}
              filesToUpload={filesToUpload}
              onFilesUploaded={() => invalidateFiles()}
              filesToDelete={filesToDelete}
              onFilesDeleted={() => invalidateFiles()}
              getOfferData={getOfferData}
              downloadPdf={handleDownloadPdf}
              previewPdf={previewPdf}
              getPdf={getPdf}
              orderLinesBuilderRef={orderLinesBuilderRef}
              linesModified={linesModified}
              filesModified={filesToUpload.length > 0 || filesToDelete.length > 0}
              selectedCustomer={contact ?? undefined}
              selectedContactPerson={contactPerson ?? undefined}
              editorValidState={editorValidState}
            />
          )}
        >
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-6">
              <OrderLinesBuilderProvider
                cacheKey={["offer-lines", offer.id]}
                dataFn={() => api.getOfferLines({ offerId: offer.id })}
                transformerFn={offerLineToOrderLineTransformer}
                onChange={() => registerModified()}
                initialVatPercent={offer.vatPercent ?? me.company.vatPercent ?? 0}
              >
                <>
                  <KpiSection formState={formState} />
                  <hr className="w-95/100 self-center text-zinc-500" />
                </>

                <>
                  <h2 className="m-0">{t("offers:latest_activity")}</h2>
                  <ActivityLogSection offerId={offer.id} />
                  <hr className="w-95/100 self-center text-zinc-500" />
                </>

                <h2 className="m-0">{t("common:offer")}</h2>

                {/* Title section*/}
                <OfferViewTitleSection
                  formState={formState}
                  editMode={!isLocked}
                  onTypeChange={handleTypeChange}
                />

                {/* Content section*/}
                <h2 className="m-0">{t("common:details")}</h2>
                <div
                  className={twMerge(
                    isPreview ? "" : "rounded-lg border border-gray-300 bg-white shadow-md"
                  )}
                >
                  <>
                    {/* Editor container */}
                    <div
                      className={twMerge(
                        "px-20 py-16 text-lg text-zinc-900",
                        isPreview ? "hidden" : ""
                      )}
                    >
                      {/* Show content loader if no company data is available yet */}
                      {!me.company && (
                        <ContentLoader className="h-6 w-full">
                          <rect x={0} y={0} width="80vw" height="100vh"></rect>
                        </ContentLoader>
                      )}
                      {me.company && (
                        <>
                          <div className="flex flex-col gap-8">
                            <HeaderSection
                              selectedCustomer={contact}
                              selectedContactPerson={contactPerson}
                              editMode={!isLocked}
                              companyData={me.company}
                              formState={formState}
                              offerNumber={offerNumber}
                              onSelectCustomer={handleSelectCustomer}
                              onSelectContactPerson={handleSelectContactPerson}
                            />

                            {/* Section to reflect the title of the offer */}
                            <div className="flex text-3xl">
                              {t(`offers:type.${formState.getValue("type")}`)}:{" "}
                              {formState.getValue("title")}
                            </div>

                            <DescriptionSection
                              editMode={!isLocked}
                              formState={formState}
                              onValidStateChange={setEditorValidState}
                            />

                            <OfferLinesRuleSelector
                              initialRuleId={offer.offerLinesRule}
                              disabled={isLocked}
                              ref={distributionRuleRef}
                              formState={formState}
                            />

                            {canSetManualPrice && (
                              <ManualPricingSection formState={formState} editMode={!isLocked} />
                            )}

                            <div className="text-base">
                              <OrderLinesBuilder
                                ref={orderLinesBuilderRef}
                                offerLinesRule={formState.getValue("offerLinesRule")}
                                editMode={!isLocked}
                                companyVatPercent={me.company.vatPercent}
                                showAll={true}
                                overwriteSubAmount={formState.getValue("manualTotalSalesPrice")}
                                options={{
                                  orderLinesActionVisibility: {
                                    showAddProduct: true,
                                    showAddHours: true,
                                    showAddText: true,
                                    showAddProductBundle: true,
                                    showVAT: true,
                                    showSubtotal: true,
                                    showTotal: true,
                                    sumCostPrice: false,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {/* Preview container */}
                    <div
                      className={twMerge(isPreview ? "flex flex-col gap-12" : "hidden")}
                      dangerouslySetInnerHTML={{ __html: previewHTML ?? "" }}
                    ></div>

                    {/* Read only data from editor (for PDF print) */}
                    <div ref={contentRef} className="hidden px-20 py-16 text-lg text-zinc-900">
                      <div className="flex flex-col gap-8">
                        <HeaderSection
                          selectedCustomer={contact}
                          selectedContactPerson={contactPerson}
                          editMode={false}
                          companyData={me.company}
                          formState={formState}
                          offerNumber={offerNumber}
                        />
                        {/* Section to reflect the title of the offer */}
                        <div className="flex text-3xl">
                          {t(`offers:type.${formState.getValue("type")}`)}:{" "}
                          {formState.getValue("title")}
                        </div>
                        <DescriptionSection editMode={false} formState={formState} />
                        <OrderLinesBuilder
                          offerLinesRule={formState.getValue("offerLinesRule")}
                          editMode={false}
                          overwriteSubAmount={formState.getValue("manualTotalSalesPrice")}
                        />
                      </div>
                    </div>
                  </>
                </div>
                {/* File section */}

                <div className="flex items-center gap-2">
                  <h2 className="m-0">{t("common:files")}</h2>
                  <Tooltip
                    trigger={
                      <span>
                        <QuestionMarkCircleIcon className="h-6 w-6" />
                      </span>
                    }
                  >
                    {t("offers:files_tooltip")}
                  </Tooltip>
                </div>
                <div className="flex flex-col gap-8">
                  <OfferViewFileSection
                    editMode={!isLocked}
                    files={files}
                    loading={isFetchingFiles}
                    onSelectFiles={handleSelectFiles}
                    onRemoveFile={handleRemoveFile}
                  />
                </div>
              </OrderLinesBuilderProvider>
            </div>
          </div>
        </PageLayout>
      )}
    </Suspense>
  );
}
