import { Invoice } from "@apacta/sdk";
import { ComponentProps, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useMe } from "~/lib/auth/use-me";
import { useFormState } from "~/lib/form-state";
import { Button, getIcon, LabelInput } from "~/lib/ui";
import { TextArea } from "~/lib/ui/form-elements";
import { CheckboxLine } from "~/lib/ui/form-elements/checkbox-line";
import { formatCurrency } from "~/lib/utils/number";
import { useMutation } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { blobToBase64 } from "~/lib/utils/utils";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";

export function InvoiceSendFragment({
  invoice,
  onChange,
}: {
  invoice: Invoice;
  /** This triggers on render and if anything changes */
  onChange: (change: {
    recipientEmail: string;
    subject: string;
    body: string;
    attachProjectOverviewPDF: boolean;
    isValid: boolean;
  }) => void;
}) {
  const { t } = useTranslation();
  const me = useMe();
  const api = useAPI();

  const [projectPreviewBlob, setProjectPreviewBlog] = useState<string>();
  const projectPreview = useMutation({
    mutationKey: ["projectPreview", invoice.project.id],
    mutationFn: async () => {
      const blob = await api.viewAsPDF({ projectId: invoice.project.id });
      return blobToBase64(blob);
    },
    onSuccess: () => {
      // disable default toast
    },
  });

  const formstate = useFormState({
    schema: {
      recipientEmail: z.string().email(),
      subject: z.string().min(1),
      body: z.string().min(1),
      attachProjectOverviewPDF: z.boolean(),
    },
    initialValues: {
      recipientEmail: invoice?.contact?.email ?? invoice?.project?.contact?.email ?? "",
      subject: t("invoices:invoice_email_subject", {
        defaultValue: "Invoice from {{companyName}}",
        replace: { companyName: me.company.name },
      }),
      body: t("invoices:invoice_email_body", {
        defaultValue:
          "Thanks for using {{companyName}}.\n\nEnclosed is invoice {{invoiceNumber}} of {{invoiceTotal}} DKK with VAT.\n\nIf you have any questions, you're always welcome to contact us.\n\nBest regards,\n{{companyName}}",
        replace: { companyName: me.company.name, invoiceTotal: invoice.grossPayment },
      }),
      attachProjectOverviewPDF: false,
    },
  });
  const values = formstate.getValues();

  function handleFieldChange<F extends keyof typeof values>(field: F, value: (typeof values)[F]) {
    formstate.onChange(field, value);
    // Inform parent
    onChange({
      ...values,
      [field]: value,
      isValid: formstate.isValid,
    });
  }

  async function handleProjectPreview() {
    const res = await projectPreview.mutateAsync();
    setProjectPreviewBlog(res);
  }

  // This is not ideal. I'm considering adding event listeners to formstate.
  // So, that it's possible for the parent to set a callback that triggers on change.
  // TODO: Revisit this later. Works well enough for now.
  useEffect(() => {
    onChange({ ...values, isValid: formstate.isValid });
  }, [formstate.isValid]);

  return (
    <>
      <LabelInput
        required
        defaultValue={invoice?.contact?.email ?? invoice?.project?.contact?.email ?? ""}
        label={t("common:email")}
        onChange={(e) => handleFieldChange("recipientEmail", e.currentTarget.value)}
        type="email"
        error={formstate.getField("recipientEmail").errors[0]}
      />
      <LabelInput
        defaultValue={t("invoices:invoice_email_subject", {
          defaultValue: "Invoice from {{companyName}}",
          replace: { companyName: me.company.name },
        })}
        label={t("common:subject")}
        required
        onChange={(e) => handleFieldChange("subject", e.currentTarget.value)}
        error={formstate.getField("subject").errors[0]}
      />
      <TextArea
        initialValue={t("invoices:invoice_email_body", {
          defaultValue:
            "Thanks for using {{companyName}}.\n\nEnclosed is invoice {{invoiceNumber}} of {{invoiceTotal}} DKK with VAT.\n\nIf you have any questions, you're always welcome to contact us.\n\nBest regards,\n{{companyName}}",
          replace: {
            companyName: me.company.name,
            invoiceTotal: formatCurrency(invoice.grossPayment ?? 0),
            invoiceNumber: invoice.invoiceNumber ?? "N/A",
          },
        })}
        errorMessage={formstate.getField("body").errors[0]}
        onChange={(s) => handleFieldChange("body", s)}
        label={t("common:message")}
        fullHeight={true}
        className="h-36"
        required={true}
      />
      <div className="mb-4 flex flex-row items-center justify-between">
        <CheckboxLine
          label={t("projects:attach_project_overview_pdf")}
          checked={values.attachProjectOverviewPDF}
          onChange={(e) => handleFieldChange("attachProjectOverviewPDF", e.currentTarget.checked)}
        />
        <Button
          loading={projectPreview.isPending}
          disabled={projectPreview.isPending}
          size="small"
          variant="primary"
          onClick={handleProjectPreview}
          Icon={getIcon("preview")}
        >
          {t("common:see")} {t("projects:overview").toLocaleLowerCase()}
        </Button>
      </div>
      <FullScreenFilePreview
        open={!!projectPreviewBlob}
        fileUrl={projectPreviewBlob}
        onClose={() => setProjectPreviewBlog(undefined)}
      />
    </>
  );
}

export type SendData = Parameters<ComponentProps<typeof InvoiceSendFragment>["onChange"]>[0];
