import { PageLayout } from "~/lib/ui/page-layout";
import { useAPI } from "~/lib/api";
import { Button, Dialog, getIcon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { IListInvoicesFilterEnum, Invoice } from "@apacta/sdk";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { formatDate, useLocale } from "~/lib/utils/date";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { formatCurrency } from "~/lib/utils/number";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { linkToCustomer, linkToInvoiceV3, linkToProject } from "~/lib/utils";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { useToastOnError } from "~/lib/utils/hooks";
import { FilterGroup } from "~/lib/ui/filters/filter-group";
import * as Popover from "@radix-ui/react-popover";
import { useState } from "react";
import { CreateInvoiceDialog } from "./_cmp/create-invoice-dialog";
import { useNavigate } from "react-router";
import { InvoiceActivityLogContent } from "./_cmp/activity-log-content";
import { InvoiceImage } from "./_cmp/invoice-image";
import { InvoiceSendDialog } from "./_cmp/invoice-send-dialog";
import { getInvoiceType, getInvoiceEntity } from "./_cmp/get-invoice-type";

export const CACHE_INVOICES = "invoices";
const columnHelper = createColumnHelper<Invoice>();

export default function InvoicesPage() {
  const { t } = useTranslation();
  const api = useAPI();
  const locale = useLocale();
  const queryClient = useQueryClient();
  const [selectedInvoiceForActivityLog, setSelectedInvoiceForActivityLog] = useState<string>();
  const [selectedInvoiceForSendDialog, setSelectedInvoiceForSendDialog] = useState<Invoice>();
  const [searchParams, setSearchParams] = useTypedSearchParams<{
    status?: IListInvoicesFilterEnum;
  }>();
  const navigate = useNavigate();

  const columns = useDataColumns(
    () => [
      columnHelper.accessor("invoiceNumber", {
        id: "invoice_number",
        header: t("invoices:invoice_number", "Invoice number"),
        cell: ({ row }) => (
          <div className="flex items-center justify-between gap-2">
            <OptionalLink to={`./${row.original.id}`}>
              <div className="w-8 text-right">{row.original.invoiceNumber ?? "-"}</div>
            </OptionalLink>
            <InvoiceImage invoice={row.original} />
          </div>
        ),
        enableSorting: true,
      }),
      columnHelper.accessor("created", {
        id: "issued_date",
        header: t("invoices:issued_date", "Issued date"),
        cell: ({ row }) => (
          <span title={formatDate(row.original.issuedDate)}>
            {locale.format(row.original.issuedDate, {
              shortDate: true,
            })}
          </span>
        ),
        enableSorting: true,
      }),
      columnHelper.display({
        id: "status",
        cell: ({ row }) => (
          <OptionalLink to={`./${row.original.id}`}>
            {getInvoiceType({ invoice: row.original, t })}
          </OptionalLink>
        ),
      }),
      columnHelper.accessor("project.name", {
        header: t("common:project", { count: 1 }),
        cell: ({ row }) => (
          <OptionalLink
            to={row.original.project ? linkToProject(row.original.project.id) : undefined}
          >
            {row.original.project?.name}
          </OptionalLink>
        ),
      }),
      columnHelper.accessor("grossPayment", {
        header: t("finance:total_with_vat", "Total w. VAT"),
        cell: ({ row }) =>
          row.original.netPayment ? formatCurrency(row.original.netPayment) : formatCurrency(0),
        meta: {
          className: "text-right",
        },
      }),
      columnHelper.accessor("contact.name", {
        header: t("common:customer", { count: 1 }),
        cell: ({ row }) => (
          <OptionalLink
            to={row.original.contact ? linkToCustomer(row.original.contact.id) : undefined}
          >
            {row.original.contact?.name}
          </OptionalLink>
        ),
      }),
      columnHelper.display({
        id: "actions",
        meta: {
          className: "text-right",
        },
        cell: ({ row }) => (
          <>
            <Popover.Root
              open={selectedInvoiceForActivityLog === row.original.id}
              onOpenChange={() => {
                setSelectedInvoiceForActivityLog(undefined);
              }}
            >
              <Popover.Anchor></Popover.Anchor>
              <Popover.Portal>
                <Popover.Content>
                  <InvoiceActivityLogContent invoiceId={row.original.id} />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
            <ActionButtons
              size="small"
              actions={[
                {
                  Icon: getIcon("edit"),
                  label: t("common:edit"),
                  href: `./${row.original.id}`,
                  isHidden: !row.original.isDraft || row.original.isLocked, // show for drafts only
                },
                {
                  Icon: getIcon("view"),
                  label: t("common:view"),
                  href: `./${row.original.id}`,
                  isHidden: row.original.isDraft || row.original.isLocked, // show for non-drafts only
                },
                {
                  Icon: getIcon("send"),
                  label: t("common:send"),
                  onClick: () => {
                    setSelectedInvoiceForSendDialog(row.original);
                  },
                  isHidden: row.original.isDraft,
                },
                {
                  Icon: getIcon("log"),
                  label: t("common:activity_log", "Activity log"),
                  onClick: () => {
                    setSelectedInvoiceForActivityLog(row.original.id);
                  },
                  isHidden: row.original.isLocked,
                },
                {
                  Icon: getIcon("delete"),
                  label: t("common:delete"),
                  confirm: {
                    entity: getInvoiceEntity(row.original),
                    action: "delete",
                  },
                  isHidden: !row.original.isDraft || row.original.isLocked, // only show for drafts
                  onClick: () => handleDelete(row.original.id),
                },
                {
                  Icon: getIcon("credit"),
                  label: t("invoices:create_credit_note"),
                  isHidden: row.original.isDraft, // show for non-drafts only
                  onClick: () => handleCreditNote(row.original.id),
                },
              ]}
            />
          </>
        ),
      }),
    ],
    [selectedInvoiceForActivityLog]
  );

  const tableState = useDataTableState();
  const dataQ = useQuery({
    queryKey: [
      CACHE_INVOICES,
      "index",
      tableState.state.search,
      tableState.state.pagination,
      tableState.sortingDirection,
      tableState.sortBy,
      tableState.pageNumber,
      searchParams.status,
    ],
    queryFn: () =>
      api.iListInvoices({
        page: tableState.pageNumber,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        limit: tableState.state.pagination.pageSize,
        q: tableState.state.search,
        filter: searchParams.status,
      }),
  });
  useToastOnError(dataQ.error);

  const table = useDataTable(
    {
      columns,
      tableState,
      skeletonRows: 10,
      data: dataQ.data?.data,
      isLoading: dataQ.isLoading,
      backendPagination: dataQ.data?.pagination,
    },
    {
      enableGlobalFilter: true,
      enableFilters: true,
    }
  );

  async function handleDelete(id: string) {
    await api.deleteInvoice({ invoiceId: id });
    await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, "index"] });
  }

  async function handleCreditNote(id: string) {
    const res = await api.duplicateInvoice({ invoiceId: id, asCreditNote: true });
    dataQ.refetch();
    if (!res.data?.id) {
      alert("Didn't receive an ID for the duplicated invoice. Pending API changes?");
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, "index"] });
    navigate(linkToInvoiceV3(res.data.id));
  }

  return (
    <>
      <PageLayout
        title={t("navigation:invoices.title")}
        renderActions={() => (
          <Dialog
            render={({ onClose }) => <CreateInvoiceDialog onClose={onClose} />}
            trigger={
              <Button variant="tertiary" className="print:hidden" Icon={getIcon("add")}>
                {t("common:create", {
                  defaultValue: "Create {{entity}}",
                  replace: { entity: t("common:invoice", { count: 1 }).toLocaleLowerCase() },
                })}
              </Button>
            }
          />
        )}
      >
        <DataTable
          table={table}
          search={{
            placeholder: t("invoices:search_placeholder", "Search by number, project or customer"),
          }}
          renderFilters={() => (
            <FilterGroup
              name={t("common:status")}
              onClear={() => setSearchParams("status", undefined)}
              value={searchParams.status}
              selection={{
                valueFn: (v) => v.value,
                allowMultiple: false,
                onConfirmSelection: (v) => setSearchParams("status", v[0].value),
                renderSelectionItem: (item) => item.label,
                items: [
                  {
                    value: IListInvoicesFilterEnum.Drafted,
                    label: t("common:draft"),
                  },
                  {
                    value: IListInvoicesFilterEnum.Booked,
                    label: t("common:invoice", { count: 1 }),
                  },
                ],
              }}
            />
          )}
        />
      </PageLayout>
      <Dialog
        open={selectedInvoiceForSendDialog !== undefined}
        onOpenChange={() => setSelectedInvoiceForSendDialog(undefined)}
        render={({ onClose }) =>
          selectedInvoiceForSendDialog && (
            <InvoiceSendDialog invoice={selectedInvoiceForSendDialog} onClose={onClose} />
          )
        }
      />
    </>
  );
}
