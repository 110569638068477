import { Project } from "@apacta/sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { useAPI } from "~/lib/api";
import { useLocale } from "~/lib/utils/date";
import { formatCurrency } from "~/lib/utils/number";
import { CACHE_OFFER } from "~/pages/offers/[id]";

export default function HoursOfferTab() {
  const api = useAPI();
  const { t } = useTranslation();
  const { entity: project } = useOutletContext<{ entity: Project }>();
  const { formatHours } = useLocale();

  const dataQ = useSuspenseQuery({
    queryKey: ["project", project.id, "hours", CACHE_OFFER],
    queryFn: () =>
      api.projectGetKpiCardHoursPreCalculationSubPage({
        projectId: project.id as string,
      }),
  });

  if (!dataQ.data) {
    return null;
  }

  const rows = dataQ.data?.data.preCalculations;
  const { totalCost, totalHours } = dataQ.data?.data;
  return (
    <div>
      <div className="table">
        <table className="text-left">
          <thead>
            <tr>
              <th>{t("common:name")}</th>
              <th>{t("common:quantity")}</th>
              <th>{t("common:cost_price")}</th>
              <th>{t("common:total_cost_price")}</th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((item, idx) => {
              return (
                <Fragment key={idx}>
                  <tr>
                    <td>{item.name}</td>
                    <td>{formatHours(item.quantity)}</td>
                    <td>{formatCurrency(item.costPrice)}</td>
                    <td>{formatCurrency(item.totalCostPrice)}</td>
                  </tr>
                  <tr className="bg-slate-50 font-bold">
                    <td></td>
                    <td>{formatHours(totalHours)}</td>
                    <td></td>
                    <td>{formatCurrency(totalCost)}</td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
