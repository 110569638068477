import {
  UsersGetWeekly200ResponseDateRangeInner,
  UsersGetWeekly200ResponseWeeklyViewInner,
  UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner,
  UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner,
} from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { formatDate, getDayName, useLocale } from "~/lib/utils/date";
import { useEmployeesParams } from "~/pages/employees/_cmp/use-users-params";
import { CACHE_EMPLOYEES } from "~/pages/employees";
import { Fragment } from "react";
import { getResolvedLanguage } from "~/lib/i18n/i18n";
import { daysBetween } from "~/lib/utils/date/date-utils";
import { RenderProjectsTableColumn } from "../_cmp/render-projects-table-column";
import { isWeekend, sub } from "date-fns";
import { Icon } from "~/lib/ui";
import { generateBackgroundColor } from "~/lib/utils/colors";
import { EmployeeHeader } from "~/pages/employees/_cmp/employee-header";
import { useHolidays } from "~/lib/calendar/use-holidays";
import { usePlanning } from "~/lib/planning";
import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";
import { HolidayBadge } from "~/lib/ui/badges/holiday-badge";

export default function EmployeesWeeklyTab() {
  const api = useAPI();
  const { t } = useTranslation();
  const { formatHours } = useLocale();
  const employeeParams = useEmployeesParams();
  const startDate = employeeParams.startDate ?? sub(new Date(), { days: 14 }).toISOString();
  const endDate = employeeParams.endDate ?? new Date().toISOString();
  const daysBetweenDates = daysBetween(new Date(startDate), new Date(endDate));

  const { viewDates } = usePlanning();
  const { holidays, isHoliday } = useHolidays(viewDates[0]);
  const getDayOfWeek = (day: Date) => day.getDay();

  const dataQ = useQuery({
    queryKey: ["employees.weekly", CACHE_EMPLOYEES, employeeParams],
    queryFn: () =>
      api.usersGetWeekly({
        startDate: startDate,
        endDate: endDate,
        employeeIds: employeeParams.employeeIds,
      }),
  });

  const tableHeaderClass = (date: Date) =>
    isHoliday(date) ? "bg-yellow-50" : isWeekend(date) ? "bg-gray-200" : "bg-white-primary";

  const tableHeader = (date: Date) => (
    <>
      <span className="">
        {getDayName(date, getResolvedLanguage(), {
          capitalizeFirst: true,
        })}
      </span>
      <span>
        <br />
      </span>
      <span>
        {formatDate(date, getResolvedLanguage(), {
          excludeTime: true,
          shortMonth: true,
          excludeYear: true,
        })}
      </span>
      <div className="h-5 pt-1">
        <HolidayBadge date={date} holidays={holidays} />
      </div>
    </>
  );

  const weekDateRange: Array<UsersGetWeekly200ResponseDateRangeInner> | undefined =
    dataQ?.data?.dateRange;
  const canApproveForms = dataQ?.data?.canApproveForms ?? false;
  const getFullWeek = function (week: UsersGetWeekly200ResponseDateRangeInner) {
    const fullWeek = [];
    if (week.fromDate === undefined) {
      return null;
    }
    const currentDay = new Date(week.fromDate);
    for (let i = 0; i < 7; i++) {
      fullWeek.push(new Date(currentDay));
      currentDay.setDate(currentDay.getDate() + 1);
    }

    return fullWeek;
  };

  return (
    <div className="flex flex-col gap-2">
      <h2 className="mb-2"> {t("users:weekly_overview", "Weekly overview")}</h2>
      {dataQ.isLoading && (
        <div className="relative flex h-full w-full items-center justify-center">
          <ContentLoader className={twMerge("h-6 w-auto px-2")}>
            <rect x={0} y={0} width="100%" height="100%"></rect>
          </ContentLoader>
        </div>
      )}
      {!dataQ.isLoading && dataQ?.data?.weeklyView.length === 0 && (
        <>
          <div className="mb-8 flex flex-col gap-2 border bg-gray-100 p-2">
            <div className="flex items-center gap-4">
              <div className="rounded-full bg-red-500 p-1.5 text-white">
                <Icon name="warningTriangle" className="h-5 w-5" />
              </div>
              <div className="text-sm">
                {t("users:weekly_overview.no_data_warning_message", {
                  defaultValue: "There is no weekly overview data for the past {{days}} days",
                  replace: { days: daysBetweenDates },
                })}
              </div>
            </div>
          </div>
        </>
      )}
      {!dataQ.isLoading &&
        !!dataQ?.data?.weeklyView.length &&
        dataQ.data.weeklyView.map(function (
          employeeData: UsersGetWeekly200ResponseWeeklyViewInner
        ) {
          return (
            employeeData.weeks && (
              <Fragment key={employeeData.user.id}>
                <div className="mt-5">
                  <EmployeeHeader user={employeeData.user} />
                </div>
                {employeeData.weeks.map(
                  (tableRows: UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner) => {
                    const found = weekDateRange?.find((w) => w.week == tableRows.id);
                    const fullWeek: Array<Date> | null =
                      found !== undefined ? getFullWeek(found) : null;
                    const totalApprovedHoursSum =
                      tableRows.sumHours?.reduce((pv, cv) => pv + cv.value, 0) ?? 0;
                    const totalNotApprovedHoursSum =
                      tableRows.notApprovedHours?.reduce((pv, cv) => pv + cv.value, 0) ?? 0;
                    const iconBgColor = generateBackgroundColor(employeeData.user.fullName);
                    return (
                      tableRows.week && (
                        <Fragment key={found?.week}>
                          <div className="ml-2 mt-2 flex flex-1">
                            <Icon color={iconBgColor} name="calendar" className="h-5 w-5" />
                            <div className="ml-2">{t("common:week")}</div>
                            <div className="ml-1 font-bold">{found?.week}</div>
                          </div>
                          <div className="mt-2 table">
                            <table>
                              <thead>
                                <tr
                                  key={`${employeeData.user.id}-${tableRows.week}-${tableRows.id}`}
                                >
                                  <th className="w-32 min-w-32 text-left">
                                    {t("common:projects")}
                                  </th>
                                  {fullWeek?.map(function (date) {
                                    return (
                                      <th
                                        key={`${employeeData.user.id}-${date.toISOString()}`}
                                        className={
                                          tableHeaderClass(date) +
                                          "w-32 min-w-32 overflow-hidden text-ellipsis whitespace-nowrap text-left"
                                        }
                                      >
                                        {tableHeader(date)}
                                      </th>
                                    );
                                  })}
                                </tr>
                              </thead>
                              <tbody>
                                {tableRows?.projects.map((project) => {
                                  // User entries grouped by project
                                  return (
                                    <Fragment key={`${project.id}-${tableRows.week}`}>
                                      <RenderProjectsTableColumn
                                        projectData={project}
                                        fullWeek={fullWeek}
                                        canApproveForms={canApproveForms}
                                      />
                                    </Fragment>
                                  );
                                })}
                                {/** Total hours */}
                                <tr
                                  key={`sum_of_hours-${employeeData.user.id}-${tableRows.year}-${tableRows.week}`}
                                  className="bg-shade-50 font-extrabold"
                                >
                                  <td>
                                    <div>{t("users:sum_of_hours", "Sum of hours")}</div>
                                    <div>{formatHours(totalApprovedHoursSum)}</div>
                                  </td>
                                  {fullWeek?.map((day) => (
                                    <td
                                      key={`sum-hours-${employeeData.user.id}-${tableRows.week}-${day.toISOString()}`}
                                      className={tableHeaderClass(day)}
                                    >
                                      {tableRows.sumHours &&
                                        tableRows.sumHours.map(
                                          (
                                            sumHours: UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner
                                          ) => {
                                            return (
                                              getDayOfWeek(sumHours.date) === getDayOfWeek(day) &&
                                              sumHours.value > 0 && (
                                                <Fragment
                                                  key={`sum-hours-${employeeData.user.id}-${tableRows.week}-${day.toISOString()}-${sumHours.date}`}
                                                >
                                                  {formatHours(sumHours.value)}
                                                </Fragment>
                                              )
                                            );
                                          }
                                        )}
                                    </td>
                                  ))}
                                </tr>
                                {/** Total not approved hours */}
                                {canApproveForms && (
                                  <tr
                                    key={`not_approved_hours-${employeeData.user.id}-${tableRows.year}-${tableRows.week}`}
                                    className="bg-shade-50 font-extrabold"
                                  >
                                    <td>
                                      <div>
                                        {t("users:not_approved_hours", "Not approved hours")}
                                      </div>
                                      <div>{formatHours(totalNotApprovedHoursSum)}</div>
                                    </td>
                                    {fullWeek?.map((day) => {
                                      const currentTimestamp = day.getMilliseconds();
                                      const dayOfWeek = getDayOfWeek(day);
                                      return (
                                        <td
                                          key={`${employeeData.user.id}-${currentTimestamp}-${dayOfWeek}`}
                                          className={tableHeaderClass(day)}
                                        >
                                          {tableRows.notApprovedHours &&
                                            tableRows.notApprovedHours.map(
                                              (
                                                notApprovedSum: UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner
                                              ) => {
                                                const rowDayOfWeek = getDayOfWeek(
                                                  notApprovedSum.date
                                                );
                                                return (
                                                  rowDayOfWeek === dayOfWeek &&
                                                  notApprovedSum.value > 0 && (
                                                    <>{formatHours(notApprovedSum.value)}</>
                                                  )
                                                );
                                              }
                                            )}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Fragment>
                      )
                    );
                  }
                )}
              </Fragment>
            )
          );
        })}
    </div>
  );
}
