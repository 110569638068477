import { ReactElement } from "react";
import Tooltip from "./tooltip";

export function ActionTooltipWrapper({
  children,
  tooltip,
}: {
  children: ReactElement;
  tooltip?: string;
}) {
  if (tooltip) {
    return (
      <Tooltip
        contentClassName="bg-hover text-white"
        arrowClassName="fill-hover"
        trigger={children}
        triggerAsChild={false}
        side="bottom"
      >
        {tooltip}
      </Tooltip>
    );
  }
  return children;
}
