/**
 * Purpose: One place where we can handle linking to pages that are v1 now, but may not always be.
 * Delete when everything is in React
 */

import { DEFAULT_STATUS_ID_FILTER } from "~/pages/projects";
import { getISOShortDate } from "~/lib/utils/date/date-utils";

type LinkType = "edit" | "view";

export function linkToCompanyFrontpage(): string {
  return "/frontpage";
}

export function linkToForms(): string {
  return "/frontpage/forms";
}

export function linkToForm(id: string, type: LinkType, options?: { projectId?: string }): string {
  if (type === "edit") {
    if (options?.projectId) {
      return `/companies/forms/edit/${id}?redirect_url=companies/projects/view/${options.projectId}`;
    }
    return `/companies/forms/edit/${id}?redirect_url=companies/projects/index`;
  }
  return `/companies/forms/view/${id}`;
}

export function linkToFormCreate() {
  return `/time-registration/new`;
}

export function linkToFormNew(id: string) {
  return `/time-registration/${id}`;
}

export function linkToProjectForm(projectId: string, formId: string) {
  return linkToProject(projectId, { subPage: `registrations/${formId}` });
}

export function linkToInvoice(id: string, type: LinkType): string {
  if (type === "edit") {
    return `/companies/invoices/edit/${id}`;
  }
  return `/companies/invoices/view/${id}`;
}

export function linkToInvoiceV3(id: string): string {
  return `/invoices/${id}`;
}

export function linkToNewInvoice({
  projectId,
  from,
  to,
  notInvoicedAmount,
  activities,
}: {
  projectId: string;
  from?: string;
  to?: string;
  notInvoicedAmount?: number;
  activities?: Array<string>;
}) {
  let invoiceUrl = `/companies/invoices/add?project_id=${projectId}`;
  if (notInvoicedAmount !== undefined) invoiceUrl += `&not_invoiced_amount=${notInvoicedAmount}`;
  if (from !== undefined) {
    invoiceUrl += `&date_from=${from}`;
  }
  if (to !== undefined) {
    invoiceUrl += `&date_to=${to}`;
  }
  if (activities !== undefined) {
    invoiceUrl += `&activity_ids=${activities}`;
  }

  return invoiceUrl;
}

export function linkToExpense(id: string, type: LinkType): string {
  if (type === "edit") {
    return `/companies/expenses/edit/${id}`; // TODO: Fix as soon as we have a view page for expenses
  }
  return `/companies/expenses/edit/${id}`;
}

export function linkToCustomer(id: string): string {
  return `/customers/${id}`;
}

export function linkToOffer(id: string) {
  return `/offers/${id}`;
}

export function linkToPlanning() {
  return "/planning";
}

export function linkToProject(
  id: string,
  options?: {
    subPage?: string; // Adds the string to the URL, e.g. "data"
  }
) {
  let url = `/projects/${id}/`;
  if (options?.subPage) url += options?.subPage;
  return url;
}

export function linkToProjects() {
  return `/projects/?status=${DEFAULT_STATUS_ID_FILTER}`;
}

export function linkToEmployee(id: string) {
  return `/employees/${id}`;
}

export function linkToEmployees(isActive = true) {
  return `/employees?isActive=${isActive}`;
}

export function linkToProductNew(id: string) {
  return `/products/${id}`;
}

export function linkToProduct(
  id: string,
  options?: {
    referrer?: string;
  }
) {
  let url = `/products/${id}`;
  if (options?.referrer) url += `?referrer=${options?.referrer}`;
  return url;
}

export function linkToExpenseLine(id: string) {
  return `/products/expense-lines/${id}`;
}

export function linkToVendorProduct(id: string) {
  return `/products/vendor-products/${id}`;
}

export function linkToRegistrations(approved?: boolean, fromDate?: Date, toDate?: Date) {
  const basePath = "/registrations";
  const filters = [];

  if (approved !== undefined) {
    filters.push(`approved=${approved}`);
  }

  if (fromDate) {
    filters.push(`dateFrom=${getISOShortDate(fromDate)}`);
  }

  if (toDate) {
    filters.push(`dateTo=${getISOShortDate(toDate)}`);
  }

  return basePath + (filters.length > 0 ? `?${filters.join("&")}` : "");
}
