import {
  ActionData,
  Card,
  CardAction,
  CardAndTaskMutationArgs,
  Task,
  UseCards,
  UseCardsProps,
} from "~/lib/planning";
import {
  ControlPanelProductsToTask,
  RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId,
  RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName,
  UpdateCardListing200Response,
  UpdateCardListingOperationRequest,
} from "@apacta/sdk";
import { datesInRange, endOfDay, startOfDay } from "~/lib/utils/date/date-utils";
import { usePlanningMutations } from "~/lib/planning/card-reducer.mutations";
import { useAPI } from "~/lib/api";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { useQuery } from "@tanstack/react-query";
import { taskFormStateSchema } from "~/pages/planning/_cmp/crud/state/task-form-state";
import { generateCards } from "~/pages/planning/_cmp/crud/utility/generate-cards";

import { useCategoryLabels } from "~/lib/ui/category-label/use-category-labels";
import { formatStartTime } from "~/pages/planning/_cmp/crud/utility/task-fns";

export default function useCards(props?: UseCardsProps): UseCards {
  const api = useAPI();
  const { labels } = useCategoryLabels();
  const dateRange = props?.dateRange;

  const { createCardMutation, updateCardListingMutation, deleteCardMutation } =
    usePlanningMutations();

  const cardQuery = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!dateRange,
    queryKey: !!dateRange
      ? ["getCardsWithTaskAndUsers", startOfDay(dateRange.start), endOfDay(dateRange.end)]
      : ["getCardsWithTaskAndUsers"],
    queryFn: () => {
      if (!!dateRange) {
        return api.getCardsWithTaskAndUsers({
          dateAfter: startOfDay(dateRange.start),
          dateBefore: endOfDay(dateRange.end),
        });
      }
    },
  });

  const cardRefetch = async () => {
    await cardQuery.refetch();
  };

  const cardCreate = async ({
    cards,
    task,
    deleteExistingCards,
    includeWeekends,
    products,
    formTemplateIds,
    originalCardId,
  }: {
    cards: Array<Partial<Card>>;
    task: Partial<Task>;
    deleteExistingCards?: boolean;
    includeWeekends?: boolean;
    products?: Array<ControlPanelProductsToTask>;
    formTemplateIds?: Array<string>;
    originalCardId?: string;
  }): Promise<{ cards: Array<Card>; tasks: Array<Task> }> => {
    const requests: Array<
      | RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
      | RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
    > = [];
    cards.forEach((card) => {
      if (card.taskId) {
        const request: RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId = {
          taskId: card.taskId,
          date: card.date ? startOfDay(card.date) : null,
          startTime: card.startTime || null,
          estimate: card.estimate || null,
          userId: card.userId || null,
          listIndex: card.listIndex,
          projectId: task.projectId || null,
          labelIds: task.labelIds ?? [],
          repeat: card.repeat,
          interval: card.interval,
          endTime: card.endTime,
          status: card.status || "to_do",
        };
        requests.push(request);
      } else {
        const request: RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName = {
          date: card.date ? startOfDay(card.date) : null,
          startTime: card.startTime || null,
          estimate: card.estimate || null,
          taskName: task.name || "",
          userId: card.userId || null,
          listIndex: card.listIndex,
          projectId: task.projectId || null,
          taskDescription: task.description || null,
          labelIds: task.labelIds ?? [],
          repeat: card.repeat,
          interval: card.interval,
          endTime: card.endTime,
          status: card.status || "to_do",
        };
        requests.push(request);
      }
    });

    const mutationResult = await createCardMutation.mutateAsync({
      args: {
        cards: requests,
        deleteExistingCards,
        includeWeekends,
        products,
        formTemplateIds,
        originalCardId,
      },
    });

    return { cards: mutationResult.cards, tasks: mutationResult.tasks };
  };

  const cardUpdate = async (
    args: UpdateCardListingOperationRequest & { showToast?: boolean }
  ): Promise<UpdateCardListing200Response> => {
    return updateCardListingMutation.mutateAsync({ args });
  };

  const cardRemove = async ({
    originalCard,
    from,
  }: {
    originalCard: Card;
    from: Array<string>;
  }): Promise<void> => {
    await deleteCardMutation.mutateAsync({ originalCard, from });
  };

  const cardAction = async (args: CardAction): Promise<CardAndTaskMutationArgs | void> => {
    switch (args.action) {
      case "CREATE":
        return createFlow(args.formState, args.data);
      case "UPDATE":
        return updateFlow(args.formState, args.card, args.data);
      case "DELETE":
        return deleteFlow(args.formState);
      default:
        return;
    }
  };

  const createFlow = async (
    formState: TaskFormState,
    data: ActionData
  ): Promise<CardAndTaskMutationArgs> => {
    // Dates for which to create a task
    let TASK_DATES: Array<Date> = [];

    const FROM_DATE = formState.getValue("fromDate");
    const TO_DATE = formState.getValue("toDate");

    if (FROM_DATE && TO_DATE && FROM_DATE.getTime() !== TO_DATE.getTime()) {
      // Difference in start date and end date, we may have multiple days
      TASK_DATES = datesInRange(FROM_DATE, TO_DATE, formState.getValue("includeWeekends"));
    } else if (FROM_DATE) {
      // Single day
      TASK_DATES = [FROM_DATE];
    }

    const _START_TIME = formState.getValue("startTime");

    let START_TIME: Date | null = null;
    if (_START_TIME.hour !== undefined) {
      START_TIME =
        formState.getValue("startTime").hour !== null &&
        formState.getValue("startTime").minute !== null
          ? formatStartTime(new Date(), {
              hour: formState.getValue("startTime").hour!,
              minute: formState.getValue("startTime").minute!,
            })
          : null;
    }

    const DURATION = formState.getValue("duration");

    const cards = generateCards({
      estimate: DURATION ?? null,
      dates: TASK_DATES,
      startTime: START_TIME,
      selectedUserIds: formState.getValue("employeeIds"),
      selectedIndex: data.selectedIndex,
      selectedStartDate: FROM_DATE,
      selectedEmployee: data.selectedEmployee,
      repeat: formState.getValue("repeat"),
      interval: formState.getValue("interval"),
      endTime: formState.getValue("endTime"),
      taskId: data.taskId ?? undefined,
      status: formState.getValue("status"),
    });

    const LABEL_ID = formState.getValue("labelId");

    const taskUpdate = {
      name: formState.getValue("taskName"),
      projectId: formState.getValue("projectId") || null,
      description: formState.getValue("description"),
      labelIds: LABEL_ID ? [LABEL_ID] : [],
      labels: data.selectedLabel ? [data.selectedLabel] : [],
    };

    const creationResults = await cardCreate({
      cards,
      task: taskUpdate,
      deleteExistingCards: data.deleteExisting ?? false,
      includeWeekends: formState.getValue("includeWeekends") ?? false,
      products: formState.getValue("products"),
      formTemplateIds: formState.getValue("formTemplateIds") ?? undefined,
      originalCardId: data.cardId ?? undefined,
    });

    return {
      type: "CREATE",
      cards: creationResults.cards,
      tasks: creationResults.tasks,
      deleteExistingCards: data.deleteExisting ?? false,
      originalCardId: data.cardId ?? undefined,
    };
  };

  const updateFlow = async (
    formState: TaskFormState,
    card: Card,
    data: ActionData
  ): Promise<CardAndTaskMutationArgs> => {
    // figure out if we need to run the creation flow instead
    const conditionFields: Array<keyof typeof taskFormStateSchema> = [
      "employeeIds",
      "fromDate",
      "toDate",
      "startTime",
      "includeWeekends",
      "repeat",
      "interval",
      "endTime",
    ];

    const conditionMet = conditionFields.some((field) => formState.modifiedFields.includes(field));

    if (conditionMet) {
      return createFlow(formState, {
        selectedLabel: data.selectedLabel,
        deleteExisting: true,
        taskId: card.taskId,
        cardId: card.id,
      });
    }

    const updatedCard = {
      ...card,
      estimate: formState.getValue("duration"),
      status: formState.getValue("status"),
    };

    const LABEL_ID = formState.getValue("labelId");

    const taskUpdate = {
      name: formState.getValue("taskName"),
      projectId: formState.getValue("projectId") || null,
      description: formState.getValue("description"),
      labelIds: LABEL_ID ? [LABEL_ID] : [],
      labels: labels.filter((l) => l.id === formState.getValue("labelId")),
      products: formState.getValue("products"),
      formTemplateIds: formState.getValue("formTemplateIds"),
    };

    return { type: "UPDATE", card: updatedCard, task: taskUpdate };
  };

  const deleteFlow = async (formState: TaskFormState) => {};

  return {
    cards: (cardQuery.data?.data.cards as Record<string, Card>) || {},
    tasks: (cardQuery.data?.data?.tasks as Record<string, Task>) || {},
    cardCreate,
    cardUpdate,
    cardRemove,
    cardRefetch,
    cardAction,
    isFetching: cardQuery.isFetching,
    isFetched: cardQuery.isFetchedAfterMount,
  };
}
