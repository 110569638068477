import { useAPI } from "~/lib/api";
import { linkToVendorProduct } from "~/lib/utils";
import { useTranslation } from "react-i18next";
import { FilterGroupVendors } from "~/lib/ui/filters/filter-group-vendors";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { Currency } from "~/lib/ui/currency";
import { createColumnHelper } from "@tanstack/react-table";
import { VendorProduct } from "@apacta/sdk";
import { useQuery } from "@tanstack/react-query";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { MediaPreviewLink } from "~/lib/ui/media";

const columnHelper = createColumnHelper<VendorProduct>();

export default function VendorProductsIndex() {
  const api = useAPI();
  const { t } = useTranslation();

  const [filters, setFilters] = useTypedSearchParams<{
    vendors?: Array<string>;
    direction: "desc" | "asc";
  }>();

  const columns = useDataColumns(() => {
    return [
      columnHelper.display({
        id: "productImage",
        meta: {
          className: "w-28",
        },
        cell: ({ row }) =>
          row.original.imageUrls?.thumbnail && row.original.imageUrls?.original ? (
            <MediaPreviewLink
              href={row.original.imageUrls.original}
              mimeType={row.original.imageMimeType ?? "image/*"}
              allowDownload={false}
              stopPropagation={true}
            >
              <img
                src={row.original.imageUrls.thumbnail}
                className="h-16 w-16 cursor-zoom-in rounded-lg"
              />
            </MediaPreviewLink>
          ) : (
            <img src="/image_not_found.jpg" className="h-16 w-16 rounded-lg" />
          ),
      }),
      columnHelper.accessor("name", {
        header: t("common:name"),
        cell: ({ row }) => (
          <OptionalLink to={linkToVendorProduct(row.original.id)}>
            <span className="font-semibold">{row.original.name}</span>
          </OptionalLink>
        ),
      }),
      columnHelper.accessor("buyingPrice", {
        header: t("products:latest_buying_price"),
        cell: ({ row }) => (
          <Currency>{row.original.buyingPrice ? row.original.buyingPrice : 0}</Currency>
        ),
        meta: {
          className: "text-right",
        },
      }),
      columnHelper.accessor("vendor", {
        header: t("common:vendor"),
        cell: ({ row }) => (
          <>
            {row.original.vendor?.imageUrl ? (
              <img
                src={row.original.vendor.imageUrl}
                width={60}
                alt={row.original.vendor.name}
              ></img>
            ) : (
              <div>{row.original.vendor?.name}</div>
            )}
          </>
        ),
      }),
    ];
  });

  const tableState = useDataTableState();

  const pageQ = useQuery({
    queryKey: ["vendor_products", tableState.queryKeys, filters],
    queryFn: () =>
      api.vendorProductsList({
        page: tableState.pageNumber,
        limit: tableState.state.pagination.pageSize,
        q: tableState.state.search,
        vendorId: filters.vendors ? filters.vendors[0] : undefined,
      }),
  });

  const table = useDataTable(
    {
      columns,
      tableState,
      data: pageQ.data?.data,
      isLoading: pageQ.isLoading,
    },
    {
      enableGlobalFilter: true,
    }
  );

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
        <h2 className="m-0">{t("common:vendor_product", { count: 2 })}</h2>
      </div>
      <DataTable
        table={table}
        renderFilters={() => (
          <>
            <FilterGroupVendors
              value={filters.vendors}
              onConfirmSelection={(selectedVendors) => {
                setFilters(
                  "vendors",
                  selectedVendors.map((v) => v.id)
                );
              }}
              onClear={() => setFilters("vendors", undefined)}
              allowMultiple={false}
            />
          </>
        )}
      />
    </div>
  );
}
