import { useFormState } from "~/lib/form-state";
import TextInput from "~/lib/ui/form-elements/text-input";
import Textarea from "~/lib/ui/form-elements/textarea";
import { useTranslation } from "react-i18next";
import { StreetAddressInput } from "~/lib/ui/street-address-input";
import { ZipInput } from "~/lib/ui/zip-input";
import { IGetProject200Response } from "@apacta/sdk";
import { projectFormState } from "../../[id]/data";
import ProjectCustomerSelection from "./project-customer-selection";
import { IconLine } from "~/pages/time-registration/_cmp/icon-line";
import { TimeAgo } from "~/lib/ui/time-ago";
import { timeAgo } from "~/lib/utils/date/date-utils";

export default function ProjectSection({
  project,
  formState,
}: {
  project: IGetProject200Response["data"];
  formState: ReturnType<typeof useFormState<typeof projectFormState>>;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex-1">
      <div className="mb-4 text-gray-500">
        <IconLine icon="registration">
          {project.created && (
            <>
              {t("common:created")} <TimeAgo date={project.created} />
              .&nbsp;
            </>
          )}
          {project.modified &&
            project.created &&
            timeAgo(project.modified) !== timeAgo(project.created) && (
              <>
                {t("common:edited")} <TimeAgo date={project.modified} />.
              </>
            )}
        </IconLine>
      </div>
      <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
        <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
          <TextInput {...formState.register("name")} label={t("common:name")} />
          <StreetAddressInput
            {...formState.register("streetName")}
            label={t("projects:worksite_address", { defaultValue: "Worksite address" })}
            onSelect={(item) => {
              formState.setValues({
                streetName: item.addressWithNumber,
                zipCode: item.zipCode,
                cityName: item.cityName,
                cityId: item.cityId,
              });
            }}
          />
          <div className="flex flex-row gap-4">
            <ZipInput
              placeholder={t("common:zip_code")}
              value={formState.getValue("zipCode")}
              label={t("common:zip_code", { defaultValue: "Zip code" })}
              disabled={true}
            />
            <TextInput disabled {...formState.register("cityName")} label={t("common:city_name")} />
          </div>
          <div>
            <Textarea
              initialValue={formState.getValue("description")}
              label={t("common:description")}
              onChange={(value) => formState.setValues({ description: value })}
              autoHeight={true}
            />
          </div>
        </div>
        <div className="flex-1 md:basis-1/2">
          <ProjectCustomerSelection project={project} formState={formState} />
        </div>
      </div>
    </div>
  );
}
