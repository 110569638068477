import { OrderLine } from "~/lib/ui/order-lines/lib/types";
import { useTranslation } from "react-i18next";
import { Input } from "~/lib/ui/form-elements";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";

export function OrderLinesBuilderUnitPart({
  line,
  editMode,
  parentId,
  width,
}: {
  line: Partial<OrderLine>;
  editMode: boolean;
  parentId?: string | null;
  width: string;
}) {
  const { t } = useTranslation();
  const { updateLine } = useOrderLinesBuilder();
  return (
    <div
      style={{ width }}
      className="flex flex-shrink-0 items-center justify-end px-3 py-5 text-right text-zinc-900"
    >
      {editMode ? (
        <Input
          className="text-right text-sm"
          defaultValue={line.unit}
          onChange={(e) =>
            updateLine(["unit"], { unit: e.currentTarget.value }, line.identifier, parentId)
          }
        />
      ) : (
        <span>{!!line.unit ? line.unit : t("common:each_abbr")}</span>
      )}
    </div>
  );
}
