import { useTranslation } from "react-i18next";
import { Invoice } from "@apacta/sdk";
import { Icon } from "~/lib/ui";

export function InvoiceImage({ invoice }: { invoice: Invoice }) {
  const { t } = useTranslation();
  const image = invoice.integrationImageUrl ?? "/apacta-icon-blue.svg";
  //const showLogo = (!invoice.integrationId && invoice.isDraft) || invoice.integrationId;
  const showLogo = (() => {
    if (invoice.isDraft) return false;
    return true;
  })();

  return (
    <div className="flex flex-row gap-1.5">
      {invoice.isLocked && <Icon name="lock" className="h-7 w-7" />}
      <div className="h-7 w-7">
        {image && showLogo ? (
          <img
            title={
              invoice.integrationId
                ? t("invoices:booked_by_partner_integration")
                : t("invoices:booked_by_apacta")
            }
            src={image}
            className=""
          />
        ) : undefined}
      </div>
    </div>
  );
}
