import * as ReactTooltip from "@radix-ui/react-tooltip";
import { ComponentProps, ReactElement } from "react";
import { twMerge } from "tailwind-merge";

export default function Tooltip({
  trigger,
  triggerAsChild = true,
  disabled = false,
  delay = 200,
  children,
  className,
  contentClassName,
  arrowClassName,
  side,
}: {
  trigger: ReactElement | string;
  triggerAsChild?: boolean;
  children: ReactElement | Array<ReactElement> | string;
  disabled?: boolean;
  delay?: number;
  className?: string;
  contentClassName?: string;
  arrowClassName?: string;
  side?: ComponentProps<typeof ReactTooltip.Content>["side"];
}) {
  if (disabled) {
    return <>{trigger}</>;
  }
  return (
    <ReactTooltip.Provider delayDuration={delay}>
      <ReactTooltip.Root>
        <ReactTooltip.Trigger asChild={triggerAsChild}>{trigger}</ReactTooltip.Trigger>
        <ReactTooltip.Portal>
          <ReactTooltip.Content
            sideOffset={8}
            side={side}
            aria-live="assertive"
            className={twMerge(
              "overflow-truncate pointer-events-auto max-w-sm whitespace-normal rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5",
              contentClassName
            )}
            asChild={true}
          >
            <div>
              <div className={twMerge("px-3 py-2 text-sm", className)}>
                <div className="flex items-center">
                  <div className="">{children}</div>
                </div>
              </div>
              <ReactTooltip.Arrow className={twMerge("fill-white shadow-lg", arrowClassName)} />
            </div>
          </ReactTooltip.Content>
        </ReactTooltip.Portal>
      </ReactTooltip.Root>
    </ReactTooltip.Provider>
  );
}
